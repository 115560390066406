<template>
    <div :key="componentKey" class="dashboard" v-bind:style="{ paddingBottom: '24px' }">
        <keep-alive>
            <NavigationBar id="dashboardNavigationBarId" ref="dashboardNavigationBarRef">
                <template v-slot:center>
                    {{ betgameName }}
                </template>
                <template v-slot:right>
                  <div v-if="matchRunning" v-on:click="refreshView" class="navigation_icon small" v-bind:class="{ small: true }">
                    <UpdateIcon></UpdateIcon>
                  </div>
                </template>
            </NavigationBar>
        </keep-alive>

        <!--
        <small v-if="this.matchRunning && this.isApp() && this.showRefreshHint" style="margin-top: 16px; margin-bottom: 16px; width: 100%;">
          &darr; {{ $t('dashboard.refresh')}} ({{ lastRefreshText }})
        </small>
        <small v-else-if="this.matchRunning && !this.isApp() && this.showRefreshHint" style="margin-top: 16px; margin-bottom: 16px;; width: 100%;">
          {{ $t('dashboard.refresh')}} ({{ lastRefreshText }})
        </small>
        -->
      
        <div v-if="showOdds" style="background: white; border-radius: 10px; margin: 10px auto 0; max-width: 576px;">
            <div style="margin: 10px 20px 10px 20px; padding: 5px;">
                <!-- <span class="bwin_ad_marker">-W-</span> -->
                <p style="text-align: center; font-size: small; line-height: 1.2rem;">Im Team der <b>Tippkönig</b>, aber beim <b>Wetten</b> kneifst du?! 🤔/🧐/🤫</p>
                <div style="margin-top: 8px; text-align: center;" class="bwin_button" v-on:click="deepLinkClicked">
                    <a :href="deepLink" target="_blank"><img src="../assets/icons/ic_logo_bwin_small_white.svg"></a>
                </div>
                <div class="bet-box-infotext">
                  Lizenziert (Whitelist) | 18+ | Suchtrisiko | <a target="_blank" href="https://buwei.de" style="color: darkgray">buwei.de</a> | -w-
                </div>
            </div>
        </div>

        <div v-if="this.isFutureSeason()" class="notification">
          <p>{{ $t('dashboard.new_season_hint') }}</p>
        </div>
      
      
        <div v-if="!hide202425AdClicked && this.$store.state.language === 'de' && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 36" class="adbanner">
          <div @click="close202425Ad" class="adbanner__close"></div>
          <img v-on:click="create202425Betgame" src="../assets/wettbewerb/Season202425_DE600x200-2.png" alt="">
        </div>
        <div v-else-if="!hide202425AdClicked && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 36" class="adbanner">
          <div @click="close202425Ad" class="adbanner__close"></div>
          <img v-on:click="create202425Betgame" src="../assets/wettbewerb/Season202425_EN600x200-2.png" alt="">
        </div>
        
      
        <div v-if="this.isBetgameAdmin && this.$store.state.betgame.type === 1 && this.$store.state.betgame.season.id === 14 && this.$store.state.betgame.competition.id !== 1110 && this.$store.state.betgame.competition.id !== 36 && this.$store.state.betgame.competition.id !== 139 && this.$store.state.betgame.competition.id !== 999" class="adbanner">
          <img v-if="this.$store.state.language === 'de'" v-on:click="copyBetgame" src="../assets/images/teamtip/teamtip_neue_saison-de-600x200.png" alt="">
          <img v-else v-on:click="copyBetgame" src="../assets/images/teamtip/teamtip_new_season-en-600x200.png" alt="">
        </div>
        <!--
        <div v-else-if="!this.isBetgameAdmin && this.$store.state.betgame.type === 1 && this.$store.state.betgame.season.id === 13" class="adbanner">
            <img v-if="this.$store.state.language === 'de'" v-on:click="createBattle" src="../assets/images/teamtip/teamtip_showdown_de_600x200.jpg" alt="">
            <img v-else v-on:click="createBattle" src="../assets/images/teamtip/teamtip_showdown_en_600x200.jpg" alt="">
        </div>
        -->
      
        <!-- 
        <DashboardLiveStatisticsOneMatch v-if="this.selectedDashboardLiveView === 1" :match="singleMatch"></DashboardLiveStatisticsOneMatch>
        <DashboardLiveStatisticsTwoMatches v-else-if="this.selectedDashboardLiveView === 2" :matches="twoMatches"></DashboardLiveStatisticsTwoMatches>
        <DashboardLiveStatisticsMultipleMatches v-else-if="this.selectedDashboardLiveView === 3" :runningMatchday="this.runningMatchday"></DashboardLiveStatisticsMultipleMatches>
        -->
        
        <div v-if="!enterCodeClicked && this.$store.state.user && this.$store.state.user.status === 'validation_pending'" 
             style="border-radius: 10px; border: #F54D42 4px solid; padding: 5px; background-color: white; 
             margin: 16px auto 0px; max-width: 458px;">
          <div class="dashboard-text" style="padding: 2px;">
            <h1 style="color: #F54D42">{{ $t('dashboard.finish_registration') }}</h1>
            <p style="color: #5F5D61">{{ $t('dashboard.please_enter_validation_code', {email: this.$store.state.user.email}) }}</p>
            <router-link :to="{name: 'validateregistration'}">
              <button @click="setEnterCodeClicked(1)" style="margin-top: 10px;" id="validate_registration">{{ $t('dashboard.button_enter_validation_code') }}</button>
            </router-link>
          </div>
        </div>

        <div v-if="!hideSpecialQuestionHintClicked && showSpecialQuestionMissingSolutionHint"
             style="border-radius: 10px; border: #F54D42 4px solid; padding: 5px; background-color: white; 
                 margin: 16px auto 0px; max-width: 458px;">
          <div class="dashboard-text" style="padding: 2px;">
            <p style="color: #5F5D61">{{ $t('dashboard.special_question_missing_solution_hint') }}</p>
            <router-link :to="{name: 'betgameeditspecialquestions'}">
              <button style="margin-top: 10px;" id="special_question_solutions_hint">{{ $t('dashboard.special_question_missing_solution_hint_button_text') }}</button>
            </router-link>
            <button v-on:click="hideSpecialQuestionMissingSolutionHint()" style="margin-top: 10px;">{{ $t('hide_hint') }}</button>
          </div>
        </div>
        <div v-else-if="!hideSpecialQuestionHintClicked && specialQuestionMissingSolutions && this.allMatchesFinished()"
             style="border-radius: 10px; border: #F54D42 4px solid; padding: 5px; background-color: white; 
                   margin: 16px auto 0px; max-width: 458px;">
          <div class="dashboard-text" style="padding: 2px;">
            <p style="color: #5F5D61">{{ $t('dashboard.special_question_missing_solution_hint_user') }}</p>
            <button v-on:click="mailToBetgameAdmin()" style="margin-top: 10px;">{{ $t('user_betgame_settings.button_contact_betgame_admin') }}</button>
            <button v-on:click="hideSpecialQuestionMissingSolutionHint()" style="margin-top: 10px;">{{ $t('hide_hint') }}</button>
          </div>
        </div>
        
        <!--
        <div v-if="this.isCurrentSeason() && !this.hasSchedule()" class="adbanner">
            <img src="../assets/images/teamtip/season_2023_24_600x200.png" alt="">
        </div>
        -->
      
        <!--
        <div v-if="!hideWM2023AdClicked && this.$store.state.language === 'de' && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id !== 1110" class="adbanner">
          <div @click="closeWM2023Ad" class="adbanner__close"></div>
          <img v-on:click="createWM2023Betgame" src="../assets/wettbewerb/FrauenWM_DE_600x200.jpg" alt="">
        </div>
        <div v-else-if="!hideWM2023AdClicked && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id !== 1110" class="adbanner">
          <div @click="closeWM2023Ad" class="adbanner__close"></div>
          <img v-on:click="createWM2023Betgame" src="../assets/wettbewerb/WomenWorldCup_EN_600x200.jpg" alt="">
        </div>
        -->
      
        <div v-if="!checkBetgamePointsClicked && showBetgamePointsHint"
             style="border-radius: 10px; border: #F54D42 4px solid; padding: 5px; background-color: white; 
               margin: 16px auto 0px; max-width: 458px;">
          <div class="dashboard-text" style="padding: 2px;">
            <p style="color: #5F5D61">{{ $t('dashboard.betgame_points_hint') }}</p>
            <router-link :to="{name: 'betgameedit'}">
              <button @click="setCheckBetgamePointsClicked(1)" style="margin-top: 10px;" id="betgame_points_hint">{{ $t('dashboard.betgame_points_hint_button_text') }}</button>
            </router-link>
          </div>
        </div>

        <div v-if="!checkBetgamePointsClicked && showBetgamePointsHintUser"
             style="border-radius: 10px; border: #F54D42 4px solid; padding: 5px; background-color: white; 
                 margin: 16px auto 0px; max-width: 458px;">
          <div class="dashboard-text" style="padding: 2px;">
            <p style="color: #5F5D61">{{ $t('dashboard.betgame_points_hint_user') }}</p>
            <button v-on:click="contactAdmin" style="margin-top: 10px;">{{ $t('user_betgame_settings.button_contact_betgame_admin') }}</button>
          </div>
        </div>

      <!--
      <div style="border-radius: 10px; border: #F54D42 4px solid; padding: 5px; background-color: white; 
                 margin: 16px auto 0px; max-width: 458px;">
        <div class="dashboard-text" style="padding: 2px;">
          <p style="color: #5F5D61">{{ $t('dashboard.maintenance_hint') }}</p>
        </div>
      </div>
      -->
      
      <!--
      <div v-if="!hideWMBattleAdClicked && this.$store.state.language === 'de' && this.$store.state.betgame && !isBattle()" class="adbanner">
        <div @click="closeWMBattleAd" class="adbanner__close"></div>
        <img v-on:click="createBattle" src="../assets/images/teamtip/WM_Battle_600x200_DE.jpg" alt="">
      </div>
      <div v-else-if="!hideWMBattleAdClicked && this.$store.state.betgame && !isBattle()" class="adbanner">
        <div @click="closeWMBattleAd" class="adbanner__close"></div>
        <img v-on:click="createBattle" src="../assets/images/teamtip/WM_Battle_600x200_EN.jpg" alt="">
      </div>
      -->
      
      <!--
         <div v-if="this.$store.state.betgame && !isBattle()" class="adbanner">
           <div class="adbanner__close"></div>
           <img v-on:click="createBattle" src="../assets/images/teamtip/Teamtip_Battle.jpg" srcset="../assets/images/teamtip/Teamtip_Battle@2x.jpg 2x" alt="">
         </div>
      -->
      
      <!--
        <div v-if="this.$store.state.betgame && isBattle()" style="border-radius: 10px; border: #F54D42 4px solid; padding: 5px; background-color: white; 
                   margin: 16px auto 0px; max-width: 458px;">
          <div class="dashboard-text" style="padding: 2px;">
            <p style="color: #5F5D61">Dir gefällt unsere neue Battle-Funktion? Du hast Fragen oder Verbesserungsvorschläge? Dann schick uns gerne dein <a href="mailto:kontakt@teamtip.net?subject=Feedback%20TEAMTIP%20Battle">Feedback.</a></p>
          </div>
        </div>
      -->

      <!--
      <div v-if="!hideRueckrundenAdClicked && this.$store.state.language === 'de' && this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id !== 1" class="adbanner">
        <div @click="closeRueckrundenAd" class="adbanner__close"></div>
        <img v-on:click="createBetgame" src="../assets/images/teamtip/TEAMTIP_rueckrunde_de.png" alt="">
      </div>
      <div v-else-if="!hideRueckrundenAdClicked && this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id !== 1" class="adbanner">
        <div @click="closeRueckrundenAd" class="adbanner__close"></div>
        <img v-on:click="createBetgame" src="../assets/images/teamtip/TEAMTIP_rueckrunde_en.png" alt="">
      </div>
      <div v-else-if="!hideRueckrundenAdClicked && this.$store.state.language === 'de' && this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 1" class="adbanner">
        <div @click="closeRueckrundenAd" class="adbanner__close"></div>
        <img v-on:click="createBattle" src="../assets/images/teamtip/TEAMTIP_rueckrunde_battle_de.png" alt="">
      </div>
      <div v-else-if="!hideRueckrundenAdClicked && this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 1" class="adbanner">
        <div @click="closeRueckrundenAd" class="adbanner__close"></div>
        <img v-on:click="createBattle" src="../assets/images/teamtip/TEAMTIP_rueckrunde_battle_en.png" alt="">
      </div>
      -->
      
        <div v-if="matchRunning && !this.allMatchesFinished() && this.$store.state.liveticker && this.$store.state.liveticker.length > 0" id="liveticker" class="liveticker">
          <div class="liveticker__header">
            Liveticker
            <div v-on:click="refreshLiveticker" class="liveticker__header__button">
              <UpdateIcon></UpdateIcon>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="event in this.$store.state.liveticker">
                <tr class="liveticker__meta">
                  <td class="liveticker__time">
                    <img v-if="event.Typ === 'TOR'" src="../assets/icons/ic_liveticker_tor.svg">
                    <img v-else-if="event.Typ === 'GELBROT'" src="../assets/icons/ic_liveticker_card_red_yellow.svg">
                    <img v-else-if="event.Typ === 'ROT'"src="../assets/icons/ic_liveticker_card_red.svg">
                    <img v-else-if="event.Typ === 'GELB'"src="../assets/icons/ic_liveticker_card_yellow.svg">
                    <img v-else-if="event.Typ === 'ANPFIFF'"src="../assets/icons/ic_liveticker_other.svg">
                    <img v-else-if="event.Typ === 'ABPFIFF'"src="../assets/icons/ic_liveticker_finish.svg">
                    <span v-if="event.Typ === 'TOR' || event.Typ === 'GELBROT' || event.Typ === 'GELB' || event.Typ === 'ROT'">{{ event.Minute }}. Minute</span>
                    <span v-else-if="event.Typ === 'ANPFIFF'">ANPFIFF</span>
                    <span v-else-if="event.Typ === 'ABPFIFF'">ABPFIFF</span>
                  </td>
                  <td class="liveticker__teams">
                    <img v-if="teamLogo(event.HeimId)" :src="teamLogo(event.HeimId)" width="24">
                    <svg v-else width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <path d="M16.6788228,14.4666528 C16.6784319,14.5535821 16.6782365,14.6380312 16.6782365,14.72 C16.6782365,17.92 17.0754204,17.92 17.0754204,22 L6.90751142,22 C6.90751142,18 7.30469536,18 7.30469536,14.72 C7.30469536,14.5820958 7.30416879,14.4377219 7.30311564,14.2868783 C7.16189664,12.9183228 6.95059177,11.7026967 6.66920105,10.64 L6.38887711,11.2610852 C6.32215943,11.4089048 6.15565267,11.4837271 6.0008221,11.4354635 L3.22551831,10.5703504 C3.06290094,10.5196596 2.96820511,10.350765 3.00978727,10.1855836 L4.33308578,4.92889834 C4.35386243,4.84636495 4.40656906,4.77546278 4.47961453,4.73178454 L6.72830973,3.38715539 C6.78448244,3.35361757 6.84801543,3.33879392 6.91053769,3.34222149 C6.90953086,3.3215047 6.9085221,3.3007642 6.90751142,3.28 L9.40273013,2 C10.2740705,2.5 11.1398271,2.75 12,2.75 C12.8601729,2.75 13.7202401,2.5 14.5802017,2 L16.8912572,3.18552777 C16.96049,3.22094519 17.0129393,3.27936302 17.0415261,3.3484881 C17.11879,3.33240258 17.2010587,3.34498492 17.2716903,3.38715539 L19.5203855,4.73178454 C19.5934309,4.77546278 19.6461376,4.84636495 19.6669142,4.92889834 L20.9902127,10.1855836 C21.0317949,10.350765 20.9370991,10.5196596 20.7744817,10.5703504 L17.9991779,11.4354635 C17.8443473,11.4837271 17.6778406,11.4089048 17.6111229,11.2610852 L17.330799,10.64 C17.0371467,11.7490035 16.8198213,13.0245545 16.6788228,14.4666528 Z" id="basic-jersey" :style="{'fill': teamColor(event.HeimId)}"></path>
                    </svg>
                    <span>{{ teamShortName(event.HeimId) }}</span>{{ event.Spielstand }}<span>{{ teamShortName(event.GastId) }}</span>
                    <img v-if="teamLogo(event.GastId)" :src="teamLogo(event.GastId)" width="24">
                    <svg v-else width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <path d="M16.6788228,14.4666528 C16.6784319,14.5535821 16.6782365,14.6380312 16.6782365,14.72 C16.6782365,17.92 17.0754204,17.92 17.0754204,22 L6.90751142,22 C6.90751142,18 7.30469536,18 7.30469536,14.72 C7.30469536,14.5820958 7.30416879,14.4377219 7.30311564,14.2868783 C7.16189664,12.9183228 6.95059177,11.7026967 6.66920105,10.64 L6.38887711,11.2610852 C6.32215943,11.4089048 6.15565267,11.4837271 6.0008221,11.4354635 L3.22551831,10.5703504 C3.06290094,10.5196596 2.96820511,10.350765 3.00978727,10.1855836 L4.33308578,4.92889834 C4.35386243,4.84636495 4.40656906,4.77546278 4.47961453,4.73178454 L6.72830973,3.38715539 C6.78448244,3.35361757 6.84801543,3.33879392 6.91053769,3.34222149 C6.90953086,3.3215047 6.9085221,3.3007642 6.90751142,3.28 L9.40273013,2 C10.2740705,2.5 11.1398271,2.75 12,2.75 C12.8601729,2.75 13.7202401,2.5 14.5802017,2 L16.8912572,3.18552777 C16.96049,3.22094519 17.0129393,3.27936302 17.0415261,3.3484881 C17.11879,3.33240258 17.2010587,3.34498492 17.2716903,3.38715539 L19.5203855,4.73178454 C19.5934309,4.77546278 19.6461376,4.84636495 19.6669142,4.92889834 L20.9902127,10.1855836 C21.0317949,10.350765 20.9370991,10.5196596 20.7744817,10.5703504 L17.9991779,11.4354635 C17.8443473,11.4837271 17.6778406,11.4089048 17.6111229,11.2610852 L17.330799,10.64 C17.0371467,11.7490035 16.8198213,13.0245545 16.6788228,14.4666528 Z" id="basic-jersey" :style="{'fill': teamColor(event.GastId)}"></path>
                    </svg>
                  </td>
                </tr>
                <tr class="liveticker__text">
                  <td colspan="2">{{ event.Text }}</td>
                </tr>
              </template>
              <!--
              <tr class="liveticker__meta">
                <td class="liveticker__time"><img src="../assets/icons/ic_liveticker_tor.svg"><span>17. Minute</span></td>
                <td class="liveticker__teams"><img data-v-444a9542="" src="https://teamtip.net/images/jerseys/24/jersey_unionberlin.png" width="24"><span>FCB</span> : <span>VFL</span><img data-v-444a9542="" src="https://teamtip.net/images/jerseys/24/jersey_dortmund.png" width="24"></td>
              </tr>
              <tr class="liveticker__text">
                <td colspan="2">Tor für Bayern 1:0 durch Meier; Vorlage Schmidt</td>
              </tr>
              <tr class="liveticker__meta">
                <td class="liveticker__time"><img src="../assets/icons/ic_liveticker_card_red_yellow.svg"><span>8. Minute</span></td>
                <td class="liveticker__teams"><img data-v-444a9542="" src="https://teamtip.net/images/jerseys/24/jersey_bochum.png" width="24"><span>BVB</span> : <span>VFB</span><img data-v-444a9542="" src="https://teamtip.net/images/jerseys/24/jersey_frankfurt.png" width="24"></td>
              </tr>
              <tr class="liveticker__text">
                <td colspan="2">Gelb-Rote Karte für Müller (BVB)</td>
              </tr>
              <tr class="liveticker__meta">
                <td class="liveticker__time"><img src="../assets/icons/ic_liveticker_other.svg"><span>1. Minute</span></td>
                <td class="liveticker__teams"><img data-v-444a9542="" src="https://teamtip.net/images/jerseys/24/jersey_unionberlin.png" width="24"><span>FCB</span> : <span>VFL</span><img data-v-444a9542="" src="https://teamtip.net/images/jerseys/24/jersey_dortmund.png" width="24"></td>
              </tr>
              <tr class="liveticker__text">
                <td colspan="2">Anpfiff Bayern - Bochum</td>
              </tr>
              -->
            </tbody>
          </table>
        </div>
      
        <div v-visibility-change="visibilityChange" class="dashboard-container" v-bind:class="{ fullwidth : dashboardRankings.length < 1 && betgameUsers.length < 1 }">
            <div v-if="nextMatch || matchRunning">
                
                <div v-if="((days > 3 && isTournament()) || (days < 4 || !lastMatchdayWon)) && !matchRunning" 
                     v-bind:class="{ last_minute : (days < 1 && (days || hours || minutes || seconds)) || 
                     (daysInit < 1 && (daysInit || hourssInit || minutesInit || secondsInit))}">
                    <table v-if="!countdownStopped && (days || hours || minutes || seconds)" class="dashboard-countdown">
                        <tr>
                            <td class="dashboard-countdown--value">{{ days }}</td>
                            <td class="dashboard-countdown--value">{{ hours }}</td>
                            <td class="dashboard-countdown--value">{{ minutes }}</td>
                            <td class="dashboard-countdown--value">{{ seconds }}</td>
                        </tr>
                        <tr>
                            <td class="dashboard-countdown--label">{{ days === 1 ? $t('dashboard.countdown_day')  : $t('dashboard.countdown_days') }}</td>
                            <td class="dashboard-countdown--label">{{ $t('dashboard.countdown_hours') }}</td>
                            <td class="dashboard-countdown--label">{{ $t('dashboard.countdown_minutes') }}</td>
                            <td class="dashboard-countdown--label">{{ $t('dashboard.countdown_seconds') }}</td>
                        </tr>
                    </table>
                    <table v-else-if="!countdownStopped" class="dashboard-countdown">
                      <tr>
                        <td class="dashboard-countdown--value">{{ daysInit }}</td>
                        <td class="dashboard-countdown--value">{{ hourssInit }}</td>
                        <td class="dashboard-countdown--value">{{ minutesInit }}</td>
                        <td class="dashboard-countdown--value">{{ secondsInit }}</td>
                      </tr>
                      <tr>
                        <td class="dashboard-countdown--label">{{ days === 1 ? $t('dashboard.countdown_day')  : $t('dashboard.countdown_days') }}</td>
                        <td class="dashboard-countdown--label">{{ $t('dashboard.countdown_hours') }}</td>
                        <td class="dashboard-countdown--label">{{ $t('dashboard.countdown_minutes') }}</td>
                        <td class="dashboard-countdown--label">{{ $t('dashboard.countdown_seconds') }}</td>
                      </tr>
                    </table>
                    <div class="dashboard-text">
                        <div v-if="days < 1 && !betForAllMatchdayMatches && !betForAllTodaysMatches">
                            <h1>{{ $t('dashboard.matchday_preview_title') }}</h1>
                            <p v-if="!isBattle()">{{ $t('dashboard.matchday_preview_text_1') }}</p>
                            <p v-else >{{ $t('dashboard.matchday_preview_text_1_battle') }}</p>
                        </div>
                        <div v-else-if="!betForAllMatchdayMatches && betForAllTodaysMatches">
                            <h1 v-if="!isBattle() && !isTeamsBetgame()">{{ matchdayText }}</h1>
                            <h1 v-else >{{ $t('dashboard.matchday_preview_title_4_battle') }}</h1>
                            <p v-if="!isBattle() && !isTeamsBetgame()">{{ $t('dashboard.matchday_preview_text_4') }}</p>
                            <p v-else >{{ $t('dashboard.matchday_preview_text_4_battle') }}</p>
                        </div>
                        <div v-else-if="!betForAllMatchdayMatches && !betForAllTodaysMatches">
                          <h1 v-if="!isBattle() && !isTeamsBetgame()">{{ matchdayText }}</h1>
                          <h1 v-else >{{ $t('dashboard.matchday_preview_title_2_battle') }}</h1>
                          <p v-if="!isBattle() && !isTeamsBetgame() && isTournament()">{{ $t('dashboard.round_preview_text') }}</p>
                          <p v-else-if="!isBattle()">{{ $t('dashboard.matchday_preview_text_2') }}</p>
                          <p v-else >{{ $t('dashboard.matchday_preview_text_2_battle') }}</p>
                        </div>
                        <div v-else>
                            <h1 v-if="!isBattle()">{{ matchdayText }}</h1>
                            <h1 v-else >{{ $t('dashboard.matchday_preview_title_3_battle') }}</h1>
                            <p v-if="!isBattle()">{{ $t('dashboard.matchday_preview_text_3') }}</p>
                            <p v-else >{{ $t('dashboard.matchday_preview_text_3_battle') }}</p>
                        </div>
                        <router-link :to="{ name: 'bet', params: { matchday: nextMatch.matchday }}">
                            <button v-if="!betForAllMatchdayMatches" id="bet_now_button">{{ $t('dashboard.button_edit_bets') }}</button>
                            <button v-else id="view_bets_button">{{ $t('dashboard.button_view_bets') }}</button>
                        </router-link>
                    </div>
                </div>
                
                <div v-else-if="days > 3 && lastMatchdayWon && !matchRunning && !isTournament()" class="matchdayWon">
                    <div class="dashboard-image">
                        <span>{{ matchdayWins.length }}</span>
                        <img src="../assets/achievements/ic_140_achievement_spieltagssieg.svg">
                    </div>
                    <div class="dashboard-text">
                        <h1>{{ $t('dashboard.matchday_winnner_title') }}</h1>
                        <p v-if="matchdayWins.length > 1">{{ $t('dashboard.matchday_winnner_text', { count: matchdayWins.length }) }}</p>
                        <p v-else-if="matchdayWins.length === 1">{{ $t('dashboard.matchday_winnner_first_time_text') }}</p>
                        <router-link :to="{name: 'rankingsbymatchday', params: { matchday: nextMatch.matchday - 1, push: this.pushParameter }}">
                            <button id="view_results">{{ $t('dashboard.button_view_results') }}</button>
                        </router-link>
                    </div>
                </div>

                <!-- START Live-Anzeige // muss noch mit Bedingung verknüpft werden, damit es nur während des Spieltags angezeigt wird -->
                <div v-else-if="matchRunning" class="live">
                    <div class="dashboard-image">
                        <img src="../assets/icons/ic_dashboard_live.svg">
                    </div>
                    <div class="dashboard-text">
                        <h1>{{ $t('dashboard.match_running_title') }}</h1>
                        <p>{{ $t('dashboard.match_running_text') }}</p>
                        <router-link :to="{name: 'rankingsbymatchday', params: { matchday: matchRunning.matchday }}">
                            <button id="view_live_results_1">{{ $t('dashboard.match_running_button_text') }}</button>
                        </router-link>
                    </div>
                </div>
                <!-- ENDE Live-Anzeige -->
                
            </div>

            <div v-else-if="this.$store.state.betgame && this.$store.state.betgame.competition && 
                this.$store.state.betgame.competition.type === 'TOURNAMENT' && !this.allMatchesFinished()" class="live">
              <div class="dashboard-text">
                <h1>{{ $t('dashboard.tournament_preview_title') }}</h1>
                <p>{{ $t('dashboard.tournament_preview_text') }}</p>
                <router-link :to="{name: 'rankings'}">
                  <button>{{ $t('dashboard.button_view_rankings_total') }}</button>
                </router-link>
              </div>
            </div>

            <div v-else-if="this.$store.state.betgame && this.$store.state.betgame.competition && 
                  this.$store.state.betgame.competition.id === 13 && !this.allMatchesFinished()" class="live">
              <div class="dashboard-text">
                <h1>{{ $t('dashboard.tournament_preview_title') }}</h1>
                <p>{{ $t('dashboard.match_preview_text_at') }}</p>
                <router-link :to="{name: 'rankings'}">
                  <button>{{ $t('dashboard.button_view_rankings_total') }}</button>
                </router-link>
              </div>
            </div>

            <div v-else-if="this.$store.state.betgame && this.$store.state.betgame.competition && 
                    this.$store.state.betgame.competition.id === 115 && !this.allMatchesFinished()" class="live">
              <div class="dashboard-text">
                <h1>{{ $t('dashboard.tournament_preview_title') }}</h1>
                <p>{{ $t('dashboard.match_preview_text_ch') }}</p>
                <router-link :to="{name: 'rankings'}">
                  <button>{{ $t('dashboard.button_view_rankings_total') }}</button>
                </router-link>
              </div>
            </div>
          
            <div v-else-if="matchRunning" class="live">
                <div class="dashboard-image">
                    <img src="../assets/icons/ic_dashboard_live.svg">
                </div>
                <div class="dashboard-text">
                    <h1>{{ $t('dashboard.matchday_running_title') }}</h1>
                    <p>{{ $t('dashboard.matchday_running_text') }}</p>
                    <router-link :to="{name: 'rankingsbymatchday', params: { matchday: matchRunning.matchday }}">
                        <button id="view_live_results_2">{{ $t('dashboard.button_view_live_results') }}</button>
                    </router-link>
                </div>
            </div>
            
            <div v-else-if="this.allMatchesFinished() && !specialQuestionMissingSolutions" class="leaguePaused">
                <div v-if="isBattleFirst(this.$store.state.user.id)" class="dashboard-image">
                  <img src="../assets/icons/ic_dashboard_medal_gold.svg">
                </div>
                <div v-else-if="isBattleSecond(this.$store.state.user.id)" class="dashboard-image">
                  <img src="../assets/icons/ic_dashboard_medal_silver.svg">
                </div>
                <div v-else-if="isBattleThird(this.$store.state.user.id)" class="dashboard-image">
                  <img src="../assets/icons/ic_dashboard_medal_bronze.svg">
                </div>
                <div v-else-if="isBattleWinner(this.$store.state.user.id)" class="dashboard-image">
                  <img src="../assets/icons/ic_dashboard_battle_win.svg">
                </div>
                <div v-else-if="!isBattleWinner(this.$store.state.user.id)" class="dashboard-image">
                  <img src="../assets/icons/ic_dashboard_battle_lose.svg">
                </div>
                <div v-else class="dashboard-image">
                    <img src="../assets/icons/ic_dashboard_pause.svg">
                </div>
                <div class="dashboard-text">
                    <h1 v-if="isBattleFirst(this.$store.state.user.id)">{{ $t('dashboard.gold_title') }}</h1>
                    <h1 v-else-if="isBattleSecond(this.$store.state.user.id)">{{ $t('dashboard.silver_title') }}</h1>
                    <h1 v-else-if="isBattleThird(this.$store.state.user.id)">{{ $t('dashboard.bronze_title') }}</h1>
                    <h1 v-else-if="isBattlePlace(this.$store.state.user.id)">{{ $t('dashboard.place_title') }}</h1>
                    <h1 v-else-if="isBattle() && isBattleWinner(this.$store.state.user.id)">{{ $t('dashboard.summer_break_title_battle_winner') }}</h1>
                    <h1 v-else-if="isBattle() && !isBattleWinner(this.$store.state.user.id)">{{ $t('dashboard.summer_break_title_battle_default') }}</h1>
                    <h1 v-else-if="!isBattle() && isBattleWinner(this.$store.state.user.id)">{{ $t('dashboard.summer_break_title_battle_winner') }}</h1>
                    <h1 v-else-if="!isBattle() && !isBattleWinner(this.$store.state.user.id)">{{ $t('dashboard.summer_break_title_battle_default') }}</h1>
                    <h1 v-else>{{ $t('dashboard.summer_break') }}</h1>
                    <p v-if="isBattleFirst(this.$store.state.user.id)">{{ $t('dashboard.gold_text') }}</p>
                    <p v-else-if="isBattleSecond(this.$store.state.user.id)">{{ $t('dashboard.silver_text') }}</p>
                    <p v-else-if="isBattleThird(this.$store.state.user.id)">{{ $t('dashboard.bronze_text') }}</p>
                    <p v-else-if="isBattlePlace(this.$store.state.user.id)">{{ $t('dashboard.place_text') }}</p>
                    <p v-else-if="this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 10700">{{ $t('dashboard.summer_break_text_em') }}</p>
                    <p v-else-if="this.$store.state.betgame && isBattle() && !isBattleWinner(this.$store.state.user.id)">{{ $t('dashboard.summer_break_text_battle_default') }}</p>  
                    <p v-else-if="this.$store.state.betgame && isBattle() && isBattleWinner(this.$store.state.user.id)">{{ $t('dashboard.summer_break_text_battle_winner') }}</p>
                    <p v-else-if="this.$store.state.betgame && !isBattle() && isBattleWinner(this.$store.state.user.id)">{{ $t('dashboard.summer_break_title_battle_winner_text') }}</p>
                    <p v-else-if="this.$store.state.betgame && !isBattle() && !isBattleWinner(this.$store.state.user.id)">{{ $t('dashboard.summer_break_title_battle_default_text') }}</p>
                    <!-- <p v-else-if="this.$store.state.betgame && !isBattle() && isBattleWinner(this.$store.state.user.id)">12 Punkte<br>5 Spieltagssiege<br>4 Exakte Tipps</p> -->
                    <p v-else>{{ $t('dashboard.summer_break_text') }}</p>
                    <!-- <p v-if="isBattle() && isBattleWinner(this.$store.state.user.id)"><b>{{ getUserPointsText(this.$store.state.user.id) }}</b></p> -->
                    <div v-if="isBattle() && isBattleWinner(this.$store.state.user.id)" class="powered-by-teamtip" style="">
                      <button v-on:click="createBattle">{{ $t('dashboard.button_start_battle') }}</button>
                    </div>
                    <div v-else-if="isBattle() && !isBattleWinner(this.$store.state.user.id)" class="powered-by-teamtip" style="">
                      <button v-on:click="createBattle">{{ $t('dashboard.button_start_battle') }}</button>
                    </div>
                    <div v-else-if="!isBattle() && isBattleWinner(this.$store.state.user.id)" class="powered-by-teamtip" style="">
                      <button v-on:click="createBetgame">{{ $t('dashboard.button_create_betgame') }}</button>
                    </div>
                    <div v-else-if="!isBattle() && !isBattleWinner(this.$store.state.user.id)" class="powered-by-teamtip" style="">
                      <button v-on:click="createBetgame">{{ $t('dashboard.button_create_betgame') }}</button>
                    </div>
                    <div v-else-if="this.isBetgameAdmin" class="powered-by-teamtip" style="">
                      <button v-on:click="showRankings">{{ $t('dashboard.button_view_rankings_total') }}</button>
                    </div>
                    <div v-else-if="!this.isBetgameAdmin" class="powered-by-teamtip" style="">
                      <button v-on:click="showRankings">{{ $t('dashboard.button_view_rankings_total') }}</button>
                    </div>
                    <!--
                    <router-link :to="{name: 'rankings'}">
                        <button>{{ $t('dashboard.button_view_rankings_total') }}</button>
                    </router-link>
                    -->
                </div>
            </div>
        </div>
        
        <RankingsTable v-if="this.hasSeasonStarted() && dashboardRankings.length > 0" :showButton="true" :rankings="dashboardRankings"></RankingsTable>
        <BetgameUsersTable v-else-if="(!this.hasSeasonStarted()) && betgameUsers.length > 0" :betgameUsers="betgameUsers"></BetgameUsersTable>

        <ProfileTrendBox v-if="!isBattle() && !isTournament() && !isTeamsBetgame() && $store.state.currentMatchdayRankingByMatchday > 0 && $store.state.bets && $store.state.bets.length > 0 && $store.state.matches && finishedMatches > 0"></ProfileTrendBox>

        <div class="table-view" v-if="this.$store.state.betgame && this.$store.state.betgame.name && this.$store.state.betgame.description && this.$store.state.betgame.description.length > 0 && 
            this.$store.state.betgame.name.trim() !== this.$store.state.betgame.description.trim()">
          <div class="table-view-header">
            {{ $t('dashboard.betgame_description') }}
          </div>
          <div class="list">
            <p class="" v-html="this.formatBetgameDescription(this.$store.state.betgame.id, this.$store.state.betgame.description)"></p>
          </div>
        </div>
      
        <div style="padding-top: 30px;"></div>

      <transition v-if="overlayOpened" name="review-modal">
        <div class="review-modal-mask">
          <div class="review-modal-wrapper">
            <div class="review-modal-container">
              <div class="input-header">
                {{ $t('review_request_title') }}
              </div>
              <div class="input-infotext">
                {{ $t('review_request_text') }}
              </div>
              <star-rating style="justify-content: center;" @rating-selected="ratingChanged($event)" :rating="rating" :show-rating="false"></star-rating>
              <div class="review-modal-footer">
                <button class="review-modal-default-button" @click="ratingSkipped()">
                  {{ $t('later') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
      
    </div>
</template>

<script>
import moment from 'moment'
import RankingsTable from '../components/RankingsTable'
import NavigationBar from './../components/NavigationBar'
import ProfileTrendBox from './../components/ProfileTrendBox'
import Vue from 'vue'
import UpdateIcon from './../components/icons/UpdateIcon'
import BetgameUsersTable from '@/components/BetgameUsersTable'
import { MATCH_DATE_FORMAT, TYPE_TEAMS_ID } from '@/main'
import { isToday } from '@/vuex/helpers'
import StarRating from 'vue-star-rating'
import DashboardLiveStatisticsOneMatch from '@/components/DashboardLiveStatisticsOneMatch.vue'
import DashboardLiveStatisticsTwoMatches from '@/components/DashboardLiveStatisticsTwoMatches.vue'
import DashboardLiveStatisticsMultipleMatches from '@/components/DashboardLiveStatisticsMultipleMatches.vue'
import axios from 'axios'
import jwt_decode from 'jwt-decode'

export default {
        components: { DashboardLiveStatisticsOneMatch, DashboardLiveStatisticsTwoMatches, DashboardLiveStatisticsMultipleMatches, BetgameUsersTable, RankingsTable, NavigationBar, ProfileTrendBox, UpdateIcon, StarRating },
        data () {
            return {
                showRefreshHint: false,
                lastRefreshText: '',
                pushParameter: '',
                previousRoute: '',
                rating: 0,
                overlayOpened: false,
                deepLink: 'https://sports.bwin.de/de/sports/fussball-4/wetten?wm=5047188',
                now: moment(),
                date: null,
                diff: null,
                interval: null,
                countdownStopped: false,
                secondsInit: 0,
                minutesInit: 0,
                hourssInit: 0,
                daysInit: 0,
                componentKey: 0,
                hide202425AdClicked:  (window.localStorage.getItem('hide202425AdClicked') !== undefined &&
                    window.localStorage.getItem('hide202425AdClicked') !== null &&
                    (window.localStorage.getItem('hide202425AdClicked') === 1 ||
                        window.localStorage.getItem('hide202425AdClicked') === '1')),
                hideSpecialQuestionHintClicked: (window.localStorage.getItem('hideSpecialQuestionMissingSolutionHint') !== undefined &&
                    window.localStorage.getItem('hideSpecialQuestionMissingSolutionHint') !== null &&
                    (window.localStorage.getItem('hideSpecialQuestionMissingSolutionHint') === 1 ||
                    window.localStorage.getItem('hideSpecialQuestionMissingSolutionHint') === "1")),
                hideRueckrundenAdClicked: (window.localStorage.getItem('hideRueckrundenAdClicked') !== undefined &&
                  window.localStorage.getItem('hideRueckrundenAdClicked') !== null &&
                  (window.localStorage.getItem('hideRueckrundenAdClicked') === 1 ||
                      window.localStorage.getItem('hideRueckrundenAdClicked') === '1')),
                testData: [
                  {
                    "Typ": "ABPFIFF",
                    "Text": "Ende 2. Halbzeit",
                    "Tickerzeit": "02.10.2021 17:24:48",
                    "Minute": "",
                    "Heim": "VfL Wolfsburg",
                    "Gast": "Bor. Mönchengladbach",
                    "HeimId": "24",
                    "GastId": "15",
                    "Datum": "02.10.2021",
                    "Uhrzeit": "15:30",
                    "Spieltag": "7",
                    "Verein": "",
                    "VereinId": "",
                    "Spielstand": "1:3"
                  },
                  {
                    "Typ": "TOR",
                    "Text": "Tor für Bor. Mönchengladbach durch Scally",
                    "Tickerzeit": "02.10.2021 17:23:40",
                    "Minute": "90+5",
                    "Heim": "VfL Wolfsburg",
                    "Gast": "Bor. Mönchengladbach",
                    "HeimId": "24",
                    "GastId": "15",
                    "Datum": "02.10.2021",
                    "Uhrzeit": "15:30",
                    "Spieltag": "7",
                    "Verein": "Bor. Mönchengladbach",
                    "VereinId": "15",
                    "Spielstand": "1:3"
                  },
                  {
                    "Typ": "ABPFIFF",
                    "Text": "Ende 2. Halbzeit",
                    "Tickerzeit": "02.10.2021 17:22:19",
                    "Minute": "",
                    "Heim": "Borussia Dortmund",
                    "Gast": "FC Augsburg",
                    "HeimId": "17",
                    "GastId": "91",
                    "Datum": "02.10.2021",
                    "Uhrzeit": "15:30",
                    "Spieltag": "7",
                    "Verein": "",
                    "VereinId": "",
                    "Spielstand": "2:1"
                  }
                ]
            }
        },
      beforeRouteEnter(to, from, next) {
        next(vm => {
          vm.previousRoute = from.name
        })
      },
      created () {
        Vue.$log.debug('dashboard created ' + this.$route.query.push)
        //this.$router.push('/feed/dashboard')
        this.$router.push({ path: '/feed/dashboard', replace: true })
        this.pushParameter = this.$route.query.push
        if(this.$store.state.betgame && this.$store.state.betgame.fk_user &&
            this.$store.state.user && this.$store.state.betgame.fk_user === this.$store.state.user.id) {
          const d = new Date();
          d.setTime(d.getTime() + (1*24*60*60*1000));
          let expires = "expires="+ d.toUTCString();
          document.cookie = "SPMAUTH=1;" + expires + ";path=/";
        } else {
          document.cookie = 'SPMAUTH=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
        }
        if(this.$store.state.betgameId && this.$store.state.betgame && !this.$store.state.betgame.season) {
          this.$store.dispatch('fetchBetgame', this.$store.state.betgameId)
        }
        if(this.$store.state.betgame && this.$store.state.betgame.type === TYPE_TEAMS_ID && 
            (!this.$store.state.betgameTeams || this.$store.state.betgameTeams.length === 0)) {
          this.$store.dispatch('fetchBetgameTeams', this.$store.state.betgameId).then(() => {
            this.$store.dispatch('fetchSchedule', this.$store.state.leagueId).then(() => {
              this.componentKey += 1
            })
          })
        }

        const lastFetchSchedule = this.getBGValue('spmlfs')
        const duration2 = (new Date() - new Date(lastFetchSchedule)) / 1000
        const cachingDuration = Math.min(this.$store.getters.getCachingDuration, 60)
        if ((lastFetchSchedule !== null && duration2 > cachingDuration) || this.$store.state.matches == null || this.$store.state.matches.length === 0) {
          this.$store.dispatch('fetchSchedule', this.$store.state.leagueId).then(() => {
            this.componentKey += 1
          })
        }
          
        if(this.$store.state.leagueId) {
          //if((this.$store.state.leagueId === 1 || this.$store.state.leagueId === 2 || this.$store.state.leagueId === 3)) {
            //this.$store.dispatch('fetcheLivetickerData', this.$store.state.leagueId).then(() => {
            //  this.componentKey += 1
            //})
          //}
          if (this.$store.state.teams.length === 0 && this.$store.state.betgameId) {
            this.$store.dispatch('fetchTeams', this.$store.state.betgameId)
          }
        }

        if (this.$store.state.betgame && this.$store.state.betgame.competition &&
            this.$store.state.betgame.competition.type === 'TOURNAMENT') {
          if (this.$store.state.tournamentRounds.length === 0 || this.$store.state.tournamentMatches.length === 0) {
            if (this.$store.state.betgame.season) {
              this.$store.dispatch('fetchTournamentRounds', {
                fk_competition: this.$store.state.betgame.competition.id,
                fk_season: this.$store.state.betgame.season.id
              }).then(() => {
              })
            }
          }
        }
        
        if((!this.$store.state.matchDayWins || this.$store.state.matchDayWins.length === 0) && this.$store.state.leagueId) {
          //this.$store.dispatch('fetchMatchdayWins', this.$store.state.betgameId)
        }
        if(this.$store.state.user && (this.$store.state.missing_bets === undefined || this.$store.state.missing_bets === null || this.$store.state.missing_bets.length === 0)) {
          this.$store.dispatch('fetchUserBetgames', this.$store.state.user.id)
        }
        if(!this.isBattle()) {
          if (this.$store.state.betgame && this.$store.state.betgame.season) {
            this.$store.dispatch('fetchQuestions', []).then(function (response) {
            })
          }
          if (!this.$store.state.answers || this.$store.state.updateAnswerCount) {
            this.$store.dispatch('updateAnswerCount', false).then(function (response) {
            })
            this.$store.dispatch('fetchUserAnswers', []).then(function (response) {
            })
          }
        }
        if(this.nextMatch) {
          const n = this.nowToLocalTimeZone()
          const d = this.parseMatchdateToLocalTimeZone(this.nextMatch.matchdate).toDate()
          const range = n.diff(d)
          const dif = moment.duration(range)
          if (dif != null) {
            this.minutesInit = Math.abs(dif.get('minutes'))
            this.secondsInit = Math.abs(dif.get('seconds'))
            this.daysInit = Math.abs(dif.get('days'))
            this.hourssInit = Math.abs(dif.get('hours'))
          }
        } else {
          if (this.isBattle() || this.isTeamsBetgame()) {
            if(this.$store.state.competitions.length === 0) {
              this.$store.dispatch('fetchCompetitions', '')
            }
            this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgame.id, this.$store.state.leagueId, 0]).then(() => {
            })
          } else {
            this.$store.dispatch('fetchBetgameRankings', { fk_betgame: this.$store.state.betgame.id }).then(function (response) {
            })
          }
        }
      },
      beforeCreate() {
        Vue.$log.debug('dashboard beforeCreate')
      },
      mounted() {
        Vue.$log.debug('dashboard mounted')
            if(this.interval == null) {
              this.interval = setInterval(() => {
                if (this.nextMatch != null && this.date == null) {
                  //this.date = new Date(Date.parse(this.nextMatch.matchdate))
                  this.date = this.parseMatchdateToLocalTimeZone(this.nextMatch.matchdate).toDate()
                }
                this.now = this.nowToLocalTimeZone()
                if(this.date < this.now.toDate()) {
                  this.countdownStopped = true
                }
              }, 1000)
            }

            if(this.$store.state.betgameId && !this.hasSeasonStarted() && parseInt(this.$store.state.betgameId) > 0) {
              Vue.$log.debug('dashboard mounted fetchBetgameUsers ' + this.$store.state.betgameId)
              this.$store.dispatch('fetchBetgameUsers', this.$store.state.betgameId)
            }
        
            if(this.$store.state.user && this.$store.state.betgameId && (!this.$store.state.bets || this.$store.state.bets.length === 0)) {
              this.$store.dispatch('fetchBets', this.$store.state.user.id).then(() => {
                this.componentKey += 1
              })
              if(this.showBetgamePointsHint || this.showBetgamePointsHintUser) {
                this.$store.dispatch('fetchBetgame', this.$store.state.betgameId)
              }
            }
            if(this.$store.state.betgameId && parseInt(this.$store.state.betgameId) > 0) {
              if (this.$store.state.leagueId && !this.isTournament()) {
                this.$store.dispatch('fetchMatchdayWins', this.$store.state.betgameId).then(() => {
                  this.componentKey += 1
                })
              } else {
                if(!this.$store.state.leagueId || !this.$store.state.betgame.competition || !this.$store.state.betgame.season) {
                  this.$store.dispatch('fetchBetgameLeague', this.$store.state.betgameId).then(() => {
                    this.componentKey += 1
                  })
                } else {
                  this.componentKey += 1
                }
              }
  
              /*
              if (this.$store.state.betgame && !this.countryBlocked() && this.$store.state.betgame.hide_ads === false && this.$store.state.matchBets.length === 0 &&
                this.$store.state.user && this.$store.state.user.isadult === true && this.$store.state.betgame.competition) {
                this.$store.dispatch('fetchMatchBets', this.$store.state.betgame.competition.id).then(() => {
                  this.componentKey += 1
                })
              }
              */

              if(this.isBattle()) {
                const lastFetchLiveRanking = this.getBGValue('spmlflr')
                const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
                if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length === 0 ||
                    this.$store.state.allbets == null || this.$store.state.allbets.length === 0) {
                  this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
                    this.$store.dispatch('fetchBetsForMatchdayRankingBattle', [this.$store.state.betgameId, this.matchday]).then(() => {
                      this.setBGValue('spmlflr', new Date())
                    })
                  })

                }
              } else if(this.isTeamsBetgame()) {
                const lastFetchLiveRanking = this.getBGValue('spmlflr')
                const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
                if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length === 0 ||
                    this.$store.state.allbets == null || this.$store.state.allbets.length === 0) {
                  this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
                    this.$store.dispatch('fetchBetsForMatchdayRankingTeam', [this.$store.state.betgameId, this.matchday]).then(() => {
                      this.setBGValue('spmlflr', new Date())
                    })
                  })

                }
              } else {
                const lastFetchLiveRanking = this.getBGValue('spmlflr')
                const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
                if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length === 0 ||
                    this.$store.state.allbets == null || this.$store.state.allbets.length === 0) {
                  if (this.$store.state.leagueId && this.$store.state.betgameId) {
                    this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
                      this.componentKey += 1
                    })
                  }
                }
                if (duration1 > (this.$store.getters.getCachingDuration)) {
                  if (this.$store.state.leagueId && this.$store.state.user && this.$store.state.betgameId) {
                    //this.$store.dispatch('fetchUserRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.$store.state.user.id]).then(() => {
                    //  this.componentKey += 1
                    //})
                  }
                }
              }
              this.componentKey += 1

              if(this.previousRoute && this.previousRoute !== 'dashboard' && this.previousRoute !== 'mybetgames') {
                if(this.showSPMReviewPopup()) {
                  this.requestReview()
                }
              }
              
            } else {
              if(this.$store.getters.userBetgamesCount > 0) {
                Vue.$log.debug('dashboard mounted !this.$store.state.betgameId /mybetgames')
                this.$router.push('/mybetgames')
              } else {
                Vue.$log.debug('dashboard mounted !this.$store.state.betgameId /searchbetgame')
                this.$router.push('/searchbetgame') 
              }
            }

            if(this.$store.state.betgame && this.$store.state.user) {
              const uid = '' + this.$store.state.user.id
              const uem = '' + this.$store.state.user.email.charAt(0) + this.reverse(uid)
              const temp = this.reverse(String(this.$store.state.user.registration_date)).replaceAll('T', this.reverse(uem))
              .replaceAll(':', '').replaceAll('-', uem).concat(uid)
              if(!this.$store.state.chat_token || this.$store.state.chat_token === '') {
                if(this.$store.state.user.chatuser && this.$store.state.user.chatuser !== '') {
                  if(this.$store.state.user.chatuser.includes('-')) {
                    const t = this.$store.state.user.chatuser.split('-')
                    if(t.length === 2) {
                      this.$store.dispatch('chatLoginUser', {'user': t[0], 'password': t[1]}).then(response => {
                        this.$log.debug('dashboard mounted chatLoginUser response: ' + JSON.stringify(response))
                      })
                    }
                  } else {
                    this.$store.dispatch('chatLoginUser', {'user': uid, 'password': temp}).then(response => {
                      this.$log.debug('dashboard mounted chatLoginUser response: ' + JSON.stringify(response))
                      this.$store.dispatch('patchUser', {
                        chatuser: uid + '-' + temp
                      })
                    })
                  }
                } else {
                  /*
                  const data = {
                    '_id': uid,
                    'first_name': this.$store.state.user.nickname,
                    'email': this.$store.state.user.email,
                    'password': temp
                  }
                  this.$store.dispatch('chatCreateUser', data).then(response => {
                    this.$log.debug('dashboard mounted chatCreateUser response: ' + JSON.stringify(response))
                    this.$store.dispatch('patchUser', {
                      chatuser: uid + '-' + temp
                    })
                    this.$store.dispatch('chatLoginUser', {'user': data._id, 'password': data.password}).then(response => {
                      this.$log.debug('dashboard mounted chatLoginUser response: ' + JSON.stringify(response))
                    })
                    .catch(function (error) {
                      this.$log.debug('dashboard mounted chatLoginUser error: ' + JSON.stringify(error))
                    })
                  })
                  .catch(function (error) {
                    this.$log.debug('dashboard mounted chatCreateUser error: ' + JSON.stringify(error))
                  })
                  */
                }
              } else {
                const payload = jwt_decode(JSON.stringify(this.$store.state.chat_token))
                const a = payload.exp * 1000
                const b = moment()
                if(a < b) {
                  this.$store.dispatch('chatLoginUser', {'user': uid, 'password': temp}).then(response => {
                    this.$log.debug('app mounted chatLoginUser response: ' + JSON.stringify(response))
                  })
                }
                if(!this.$store.state.user.chatuser.includes('-')) {
                  this.$store.dispatch('patchUser', {
                    chatuser: uid + '-' + temp
                  })
                }
              }
            }
        
            window.appResumeEvent = new Event('appResumeEvent')
            window.addEventListener('appResumeEvent', this.appResumeEventFunction, false)
            window.appPullToRefreshEvent = new Event('appPullToRefreshEvent')
            window.addEventListener('appPullToRefreshEvent', this.appPullToRefreshEventFunction, false)
        },
        computed: {
          singleMatch() {
            return this.$store.getters.getMatcheById(this.$store.state.todaysMatches[0])
          },
          twoMatches() {
             return [this.$store.getters.getMatcheById(this.$store.state.todaysMatches[0]),
               this.$store.getters.getMatcheById(this.$store.state.todaysMatches[1])]
          },
          selectedDashboardLiveView() {
            if(this.$store.state.betgame.type === 1) {
              if(this.getRunningMatchdays.length !== 1) {
                return 0
              }
              if(this.$store.state.leagueId === 1 || this.$store.state.leagueId === 2 || this.$store.state.leagueId === 998) {
                var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
                if(!timeZone) {
                  timeZone = 'Europe/Berlin'
                }
                const nowww = moment.tz(timeZone)
                const t = this.calcMatchdayStartEnd
                for(var i=1; i<t.length; i++) {
                  const matchdayStart = moment.tz(String(t[i].start), timeZone)
                  const matchdayEnd = moment.tz(String(t[i].end), timeZone)
                  matchdayEnd.add(1, 'days').endOf('day')
                  if(nowww.isBetween(matchdayStart, matchdayEnd)) {
                    return 3
                  }
                }
              } else {
                if(this.$store.getters.getTodaysMatchesMatchdayStarted) {
                  if (this.$store.state.todaysMatches.length > 2) {
                    return 3
                  } else if (this.$store.state.todaysMatches.length === 2) {
                    return 2
                  } else if (this.$store.state.todaysMatches.length === 1) {
                    return 1
                  } else {
                    return 0
                  }
                } else {
                  return 0
                }
              }
            } else if(this.$store.state.betgame.type === 2) {
              if(this.$store.state.todaysMatches.length > 2) {
                return 3
              } else if(this.$store.state.todaysMatches.length === 2) {
                return 2
              } else if(this.$store.state.todaysMatches.length === 1) {
                return 1
              } else {
                return 0
              }
            } else if(this.$store.state.betgame.type === 3) {
              if(this.$store.state.todaysMatches.length > 2) {
                return 3
              } else if(this.$store.state.todaysMatches.length === 2) {
                return 2
              } else if(this.$store.state.todaysMatches.length === 1) {
                return 1
              } else {
                return 0
              }
            } else {
              return 0
            }
          },
          matchdayMatchesCount() {
            var matches = []
            if(this.$store.state.runningMatches && this.$store.state.runningMatches.length > 0) {
              const m = this.$store.getters.getMatcheById(this.$store.state.runningMatches[0])
              matches = this.$store.getters.getMatchesByMatchday(m.matchday)
            } else if(this.nextMatch) {
              matches = this.$store.getters.getMatchesByMatchday(this.nextMatch.matchday)
            }
            return matches.length
          },
          specialQuestionMissingSolutions() {
            if(!this.$store.state.questions) {
              return false
            }
            const quests = this.$store.state.questions.filter(q => parseInt(q.fk_betgame) === parseInt(this.$store.state.betgame.id))
            if(!quests || quests.length === 0) {
              return false
            }
            var result = false
            for(var i=0; i<quests.length; i++) {
              const eu = this.parseMatchdate(quests[i].editable_until)
              if (eu < Date.now() && this.$store.state.solutions) {
                const solutions = this.$store.state.solutions.filter(s => s.fk_question === quests[i].id)
                result = !solutions || solutions.length < quests[i].answer_count
                if (result) {
                  return true
                }
              }
            }
            return false
          },
          mailToTarget() {
            return this.$store.state.betgame.email
          },
          showSpecialQuestionMissingSolutionHint() {
            const t = window.localStorage.getItem('hideSpecialQuestionMissingSolutionHint')
            if(t !== undefined && t != null && (t === 1 || t === '1')) {
              return false
            }
            if(this.$store.state.betgame && this.$store.state.user && this.$store.state.betgame.fk_user === this.$store.state.user.id) {
              const quests = this.$store.state.questions.filter(q => parseInt(q.fk_betgame) === parseInt(this.$store.state.betgame.id))
              if(!quests || quests.length === 0) {
                return false
              }
              var result = false
              for(var i=0; i<quests.length; i++) {
                const eu = this.parseMatchdate(quests[i].editable_until)
                if (eu < Date.now()) {
                  const solutions = this.$store.state.solutions.filter(s => s.fk_question === quests[i].id)
                  result = !solutions || solutions.length < quests[i].answer_count
                  if (result) {
                    return true
                  }
                }
              }
            }
            return false
          },
          finishedMatches() {
            return this.$store.getters.finishedMatches
          },
          showBetgamePointsHint() {
            if(this.$store.state.betgame && this.$store.state.user && this.$store.state.betgame.bet_mode === 1 &&
                this.$store.state.betgame.fk_user === this.$store.state.user.id) {
              if(this.$store.state.betgame.points_bet_goaldiff > this.$store.state.betgame.points_bet_result ||
                  this.$store.state.betgame.points_bet_tendency > this.$store.state.betgame.points_bet_result ||
                  this.$store.state.betgame.points_bet_tendency > this.$store.state.betgame.points_bet_goaldiff) {
                return true
              }
            }
            return false;
          },
          showBetgamePointsHintUser() {
            if(this.$store.state.betgame && this.$store.state.user && this.$store.state.betgame.bet_mode === 1 &&
                this.$store.state.betgame.fk_user !== this.$store.state.user.id) {
              if(this.$store.state.betgame.points_bet_goaldiff > this.$store.state.betgame.points_bet_result ||
                  this.$store.state.betgame.points_bet_tendency > this.$store.state.betgame.points_bet_result ||
                  this.$store.state.betgame.points_bet_tendency > this.$store.state.betgame.points_bet_goaldiff) {
                return true
              }
            }
            return false;
          },
          checkBetgamePointsClicked() {
            return this.getBGValue('spmbpc')
          },
          enterCodeClicked() {
            return this.getBGValue('spmvr')
          },
          isBetgameAdmin() {
            const userid = this.$store.state.user.id
            if(userid !== undefined && this.$store.state.betgame.fk_user === userid) {
              return true
            }
            return false
          },
          isHandballBetgame() {
            return this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 533
          },
          showOdds() {
            return false
            /*
            if(this.$store.state.betgame && this.$store.state.user) {
              if(this.$store.state.betgame.id === 23916 || this.$store.state.betgame.id === 19844) {
                return false
              }
              return !this.isHandballBetgame && !this.countryBlocked() && this.$store.state.betgame.hide_ads === false && this.$store.state.user.isadult === true
            }
            return false
            */
          },
          matchdayText() {
            if(this.nextMatch === undefined) {
              return ''
            }
            const nextMatchday = this.nextMatch.matchday
            if(nextMatchday === undefined) {
              return ''
            }
            if(this.nextMatch && this.nextMatch.fk_competition === 19 || (this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 19)) {
              if(nextMatchday === 7) {
                return this.$i18n.t('ko_round_of_16_first_legs')
              } else if(nextMatchday === 8) {
                return this.$i18n.t('ko_round_of_16_second_legs')
              } else if(nextMatchday === 9) {
                return this.$i18n.t('ko_round_quarter_finals_first_legs')
              } else if(nextMatchday === 10) {
                return this.$i18n.t('ko_round_quarter_finals_second_legs')
              } else if(nextMatchday === 11) {
                return this.$i18n.t('ko_round_semi_finals_first_legs')
              } else if(nextMatchday === 12) {
                return this.$i18n.t('ko_round_semi_finals_second_legs')
              } else if(nextMatchday === 13) {
                return this.$i18n.t('ko_round_final')
              }
            } else if(this.$store.state.betgame && this.$store.state.betgame.competition && 
                (this.$store.state.betgame.competition.id === 132 || this.$store.state.betgame.competition.id === 6395)) {
              if(nextMatchday === 7) {
                return this.$i18n.t('ko_round_playoffs_first_legs')
              } else if(nextMatchday === 8) {
                return this.$i18n.t('ko_round_playoffs_second_legs')
              } else if(nextMatchday === 9) {
                return this.$i18n.t('ko_round_of_16_first_legs')
              } else if(nextMatchday === 10) {
                return this.$i18n.t('ko_round_of_16_second_legs')
              } else if(nextMatchday === 11) {
                return this.$i18n.t('ko_round_quarter_finals_first_legs')
              } else if(nextMatchday === 12) {
                return this.$i18n.t('ko_round_quarter_finals_second_legs')
              } else if(nextMatchday === 13) {
                return this.$i18n.t('ko_round_semi_finals_first_legs')
              } else if(nextMatchday === 14) {
                return this.$i18n.t('ko_round_semi_finals_second_legs')
              } else if(nextMatchday === 15) {
                return this.$i18n.t('ko_round_final')
              }
            } else if(this.$store.state.betgame && this.$store.state.betgame.competition && 
                (this.$store.state.betgame.competition.id === 10700 ||this.$store.state.betgame.competition.id === 36)) {
              if(nextMatchday === 1) {
                return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 2) {
                return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 3) {
                return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 4) {
                return this.$i18n.t('em_ko_round_of_16')
              } else if(nextMatchday === 5) {
                return this.$i18n.t('em_ko_round_quarter_finals')
              } else if(nextMatchday === 6) {
                return this.$i18n.t('em_ko_round_semi_finals')
              } else if(nextMatchday === 7) {
                return this.$i18n.t('ko_round_final')
              }
            } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 533) {
              if(nextMatchday === 1) {
                return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 2) {
                return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 3) {
                return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 8) {
                return this.$i18n.t('ko_round_fifth_place')
              } else if(nextMatchday === 9) {
                return this.$i18n.t('em_ko_round_semi_finals')
              } else if(nextMatchday === 10) {
                return this.$i18n.t('ko_round_third_place')
              } else if(nextMatchday === 11) {
                return this.$i18n.t('ko_round_final')
              }
            } else if((this.nextMatch && (this.nextMatch.fk_competition === 139 || this.nextMatch.fk_competition === 1110)) || 
                (this.$store.state.betgame && this.$store.state.betgame.competition && 
                (this.$store.state.betgame.competition.id === 139 || this.$store.state.betgame.competition.id === 1110))) {
              if(nextMatchday === 1) {
                return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 2) {
                return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 3) {
                return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 4) {
                return this.$i18n.t('em_ko_round_of_16')
              } else if(nextMatchday === 5) {
                return this.$i18n.t('em_ko_round_quarter_finals')
              } else if(nextMatchday === 6) {
                return this.$i18n.t('em_ko_round_semi_finals')
              } else if(nextMatchday === 7) {
                return this.$i18n.t('ko_round_third_place_short')
              } else if(nextMatchday === 8) {
                return this.$i18n.t('ko_round_final')
              }
            } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 1180) {
              if(nextMatchday === 1) {
                return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 2) {
                return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 3) {
                return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 4) {
                return this.$i18n.t('em_ko_round_quarter_finals')
              } else if(nextMatchday === 5) {
                return this.$i18n.t('em_ko_round_semi_finals')
              } else if(nextMatchday === 6) {
                return this.$i18n.t('ko_round_final')
              }
            }
            if(this.$store.state.tournamentRounds && this.$store.state.tournamentRounds.length > 0) {
              var matchdays = 0
              for (var i = 0; i < this.$store.state.tournamentRounds.length; i++) {
                const round = this.$store.state.tournamentRounds[i]
                matchdays += round.matchdaycount
                if(matchdays >= nextMatchday) {
                  if(this.$store.state.betgame.competition.id === 19 || this.$store.state.betgame.competition.id === 132 ||
                      this.$store.state.betgame.competition.id === 4228 || this.$store.state.betgame.competition.id === 6395) {
                    return round.name + ', ' + this.$i18n.t('matchday', { matchday: nextMatchday })
                  } else {
                    return round.name
                  }
                }
              }
            }
            return this.$i18n.t('matchday', { matchday: nextMatchday })
          },
          betForAllTodaysMatches () {
            const bets = this.$store.state.bets
            var result = false
            if (bets.length > 0 && this.nextMatch) {
              const matchdayMatches = this.$store.getters.getMatchesByMatchday(this.nextMatch.matchday)
              if (matchdayMatches.length > 0) {
                for (var i = 0; i < matchdayMatches.length; i++) {
                  const match = matchdayMatches[i]
                  if(match.status && (match.status.trim() === 'annulliert' || match.status.trim() === 'abgesagt'
                      || match.status.trim() === 'verlegt' || match.status.trim() === 'Wertung')) {
                    continue
                  }
                  const matchdate = this.parseMatchdateToLocalTimeZone(match.matchdate)
                  if(matchdate && isToday(new Date(matchdate))) {
                    const betMatches = bets.filter(betMatch => betMatch.fk_match === match.id)
                    if (betMatches.length === 0) {
                      return false
                    } else {
                      result = true
                    }
                  }
                }
              }
            }
            return result
          },
          betForAllMatchdayMatches () {
            const bets = this.$store.state.bets
            var result = false
            if (bets.length > 0 && this.nextMatch) {
              const matchdayMatches = this.$store.getters.getMatchesByMatchday(this.nextMatch.matchday)
              if (matchdayMatches.length > 0) {
                for (var i = 0; i < matchdayMatches.length; i++) {
                  const match = matchdayMatches[i]
                  if(this.isPast(match.matchdate)) {
                    continue
                  }
                  if(match.status && (match.status.trim() === 'annulliert' || match.status.trim() === 'abgesagt'
                      || match.status.trim() === 'verlegt' || match.status.trim() === 'Wertung')) {
                    continue
                  }
                  const betMatches = bets.filter(betMatch => betMatch.fk_match === match.id)
                  if (betMatches.length === 0) {
                    return false
                  } else {
                    result = true
                  }
                }
              }
            }
            return result
          },
          runningMatchday() {
            const today = this.$store.state.todaysMatches
            if (today.length > 0) {
              const match = this.$store.getters.getMatcheById(today[0])
              return match.matchday
            }
            const t = this.getRunningMatchdays
            return t.length > 0 ? t[0].matchday : 0
          },
            matchRunning() {
              return this.$store.getters.getLiveMatch
            },
            nextMatch() {
              if(this.$store.state.matches.length > 0) {
                return this.$store.getters.getClosestFutureMatch
              } else {
                if(this.$store.state.leagueId) {
                  this.$store.dispatch('fetchSchedule', this.$store.state.leagueId)
                } else {
                  if(this.getBGValue('spmbgid') && parseInt(this.getBGValue('spmbgid')) > 0) {
                    this.$store.dispatch('fetchBetgameLeague', this.getBGValue('spmbgid'))
                  }
                }
              }
            },
            betgameName() {
              if(this.$store.state.betgame) {
                return this.$store.state.betgame.name
              } else {
                if(this.getBGValue('spmbgid') && parseInt(this.getBGValue('spmbgid')) > 0) {
                  this.$store.dispatch('fetchBetgame', this.getBGValue('spmbgid'))
                }
              }
            },
            matchdayWins() {
              if(this.$store.state.user) {
                return this.$store.getters.getMatchdayWins(this.$store.state.user.id)
              }
              return []
            },
            lastMatchdayWon() {
              if(this.nextMatch && this.nextMatch.matchday > 1) {
                if (this.matchdayWins.length > 0) {
                  return this.matchdayWins.filter(matchdayWin => matchdayWin.matchday === (this.nextMatch.matchday - 1)).length > 0
                  /*
                  for (var i = 0; i < this.matchdayWins.length; i++) {
                    const matchdayWin = this.matchdayWins[i]
                    if(matchdayWin.matchday === (this.nextMatch.matchday - 1)) {
                      return true
                    }
                  }
                   */
                }
              }
              return false
            },
            dashboardRankings() {
              if(!this.$store.state.userNeighbourRankings) {
                if (!this.$store.state.leagueId && this.getBGValue('spmbgid') && parseInt(this.getBGValue('spmbgid')) > 0) {
                    this.$store.dispatch('fetchBetgameLeague', this.getBGValue('spmbgid'))
                }
              }
              return this.$store.getters.getRankingForDashboard
            },
            seconds () {
                if (this.diff != null) {
                    return Math.abs(this.diff.get('seconds'))
                } else {
                    return 0
                }
            },
            minutes () {
                if (this.diff != null) {
                    return Math.abs(this.diff.get('minutes'))
                } else {
                    return 0
                }
            },
            hours () {
                if (this.diff != null) {
                    return Math.abs(this.diff.get('hours'))
                } else {
                    return 0
                }
            },
            days () {
                if (this.diff != null) {
                    return Math.abs(this.now.diff(this.date, 'days'))
                } else {
                    return 0
                }
            },
            betgameUsers () {
              const r = this.$store.state.betgameUsers
              if(!r) {
                return []
              }
              if(r.length > 100) {
                return r.slice(0, 100)
              }
              return r
            },
          getRunningMatchdays() {
            const matchdays = this.calcMatchdayStartEnd
            var result = []
            var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            if(!timeZone) {
              timeZone = 'Europe/Berlin'
            }
            const nowww = moment.tz(timeZone)
            for(var i=0; i<matchdays.length; i++) {
              const matchday = matchdays[i]
              if(matchday && matchday.start && matchday.end) {
                const start = this.parseMatchdate(matchday.start)
                const end = this.parseMatchdate(matchday.end)

                const matchdayStart = moment.tz(String(matchday.start), timeZone)
                const matchdayEnd = moment.tz(String(matchday.end), timeZone)
                matchdayEnd.add(1, 'days').endOf('day')

                
                if(start && end) {
                  if(start.isSame(moment(Date.now()), 'day') || end.isSame(moment(Date.now()), 'day') || 
                      (start.isBefore(moment(Date.now()), 'day') && end.isAfter(moment(Date.now()), 'day'))) {
                    result.push(matchday)
                  } else if(nowww.isBetween(matchdayStart, matchdayEnd)) {
                    result.push(matchday)
                  }
                }
              }
            }
            return result
          },
          calcMatchdayStartEnd() {
            const matchesByMathday = this.groupMatchesByMathday
            var result = []
            for(var matchday = 0; matchday < matchesByMathday.length; matchday++) {
              const matchdayMatches = matchesByMathday[matchday]
              if(matchdayMatches) {
                const sorted = matchdayMatches.sort(function (a, b) {
                  return Date.parse(a.matchdate) - Date.parse(b.matchdate)
                })
                const matchdayStart = sorted[0].matchdate
                const matchdayEnd = sorted[sorted.length - 1].matchdate
                const diffInDays = 1 + Math.floor((Date.parse(matchdayEnd) - Date.parse(matchdayStart)) / (1000 * 60 * 60 * 24))
                result[matchday] = {
                  start: matchdayStart,
                  end: matchdayEnd,
                  days: diffInDays,
                  matchday: matchday
                }
              }
            }
            return result
          },
          groupMatchesByMathday() {
            var result = []
            for(var i = 0; i < this.$store.state.matches.length; i++) {
              const match = this.$store.state.matches[i]
              if(result[match.matchday] === undefined) {
                result[match.matchday] = []
              }
              result[match.matchday].push(match)
            }
            return result
          }
        },
        watch: {
            now (value) {
                if (this.now != null && this.date != null) {
                    const range = this.now.diff(this.date)
                    this.diff = moment.duration(range)
                }
            }
        },
        beforeDestroy () {
            clearInterval(this.interval)
            window.removeEventListener('appResumeEvent', this.appResumeEventFunction)
            window.removeEventListener('appPullToRefreshEvent', this.appPullToRefreshEventFunction)
        },
        methods: {
          appResumeEventFunction() {
            Vue.$log.debug('Dashboard appResumeEventFunction')
            this.visibilityChange('appResumeEvent', false)
            return 'appResumeEvent'
          },
          appPullToRefreshEventFunction() {
            Vue.$log.debug('Dashboard appPullToRefreshEventFunction')
            this.showRefreshHint = false
            this.$store.dispatch('fetchSchedule', this.$store.state.leagueId).then(() => {
              this.componentKey += 1
            })
            this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
              this.setBGValue('spmlflr', new Date())
              this.componentKey += 1
            })
            return 'appPullToRefreshEvent'
          },
          refreshView() {
            this.showRefreshHint = false
            this.componentKey += 1
            this.setBGValue('spmsrh', new Date())

          },
          createBL2023Betgame() {
            this.$gtag.event('EigeneTipprunde', {
              'event_category': 'createBL2023Betgame',
              'event_label': 'teamtip.dashboard',
              'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
              'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
              'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set',
              'dimension5': this.$store.state.betgame && this.$store.state.betgame.type ? this.$store.state.betgame.type : 'betgame type not set'
            })
            this.$router.push('/createuserbetgame?type=1&competition=1')
          },
          ratingSkipped() {
            this.overlayOpened = false
            this.$store.dispatch('addRating', {'rating': 0, 'date': new Date(), 'version': 
              this.buildVersion(), 'client': this.userAgent(), 'appOpened': this.appOpenedCount(),
              'reviewRequested': this.reviewRequestedCount(), 'info': '' } ).then(() => {})
          },
          ratingChanged(r) {
            this.rating = r
            this.overlayOpened = false
            this.$store.dispatch('addRating', {'rating': r, 'date': new Date(), 
              'version': this.buildVersion(), 'client': this.userAgent(), 'appOpened': this.appOpenedCount(),
              'reviewRequested': this.reviewRequestedCount(), 'info': '' } ).then(() => {})
          },
          requestReview() {
            window.localStorage.setItem('spmlrr', '' + new Date())
            this.$store.dispatch('setReviewPopupShown', 1)
            this.overlayOpened = true
          },
          mailToBetgameAdmin() {
            window.location.href = 'mailto:' + this.mailToTarget
          },
          isBattleFirst(user) {
            if(this.isBattle() || this.isTeamsBetgame()) {
              const user_is_first = this.$store.state.liveRankings.filter(function (item) {
                return item.position_int === 1 && item.fk_user === user;
              })
              return user_is_first && user_is_first.length === 1
            } else {
              const user_is_first = this.$store.state.betgameRankings.filter(function (item) {
                return item.position_int === 1 && item.fk_user === user;
              })
              return user_is_first && user_is_first.length === 1
            }
          },
          isBattleSecond(user) {
            if(this.isBattle() || this.isTeamsBetgame()) {
              const user_is_second = this.$store.state.liveRankings.filter(function (item) {
                return item.position_int === 2 && item.fk_user === user;
              })
              return user_is_second && user_is_second.length === 1
            } else {
              const user_is_second = this.$store.state.betgameRankings.filter(function (item) {
                return item.position_int === 2 && item.fk_user === user;
              })
              return user_is_second && user_is_second.length === 1
            }
          },
          isBattleThird(user) {
            if(this.isBattle() || this.isTeamsBetgame()) {
              const user_is_third = this.$store.state.liveRankings.filter(function (item) {
                return item.position_int === 3 && item.fk_user === user;
              })
              return user_is_third && user_is_third.length === 1
            } else {
              const user_is_third = this.$store.state.betgameRankings.filter(function (item) {
                return item.position_int === 3 && item.fk_user === user;
              })
              return user_is_third && user_is_third.length === 1
            }
          },
          isBattlePlace(user) {
            if(this.isBattle() || this.isTeamsBetgame()) {
              const user_is_placed = this.$store.state.liveRankings.filter(function (item) {
                return item.position_int > 3 && item.fk_user === user;
              })
              return user_is_placed && user_is_placed.length === 1
            } else {
              const user_is_placed = this.$store.state.betgameRankings.filter(function (item) {
                return item.position_int > 3 && item.fk_user === user;
              })
              return user_is_placed && user_is_placed.length === 1
            }
          },
          closeRueckrundenAd() {
            this.hideRueckrundenAdClicked = true
            window.localStorage.setItem('hideRueckrundenAdClicked', 1)
            this.componentKey += 1
          },
          close202425Ad() {
            this.hide202425AdClicked = true
            window.localStorage.setItem('hide202425AdClicked', 1)
            this.componentKey += 1
          },
          hideSpecialQuestionMissingSolutionHint() {
            this.hideSpecialQuestionHintClicked = true
            window.localStorage.setItem('hideSpecialQuestionMissingSolutionHint', 1)
            this.componentKey += 1
          },
          getUserPointsText(user) {
            const t = this.$store.state.liveRankings.filter(u => parseInt(u.fk_user) === parseInt(user))
            if(t.length === 1) {
              const userRanking = t[0]
              var text = userRanking.position + '. ' + this.$t('ranking_placement') + ', ' + userRanking.points_total + ' '
              if(parseInt(userRanking.points_total) === 1) {
                text += this.$t('point')
              } else {
                text += this.$t('points')
              }
              return text
            }
            return ''
          },
          refreshLiveticker() {
            this.componentKey += 1
            //if((this.$store.state.leagueId === 1 || this.$store.state.leagueId === 2 || this.$store.state.leagueId === 3)) {
              //this.$store.dispatch('fetcheLivetickerData', this.$store.state.leagueId).then(() => {
              //  this.componentKey += 1
              //})
            //}
          },
          teamColor(kickerid) {
            const team = this.$store.getters.getTeamById(parseInt(kickerid))
            if(team != null && team.color !== null && team.color.length > 0) {
              return team.color
            }
            return '#778833'
          },
          teamLogo(kickerid) {
            const team = this.$store.getters.getTeamById(parseInt(kickerid))
            if(team != null) {
              const l = this.getTeamLogo(team.id)
              if (l) {
                return this.getBGValue('spmau') + '/images/jerseys/24/' + l
              }
            }
            return null
          },
          teamShortName(kickerId) {
            const team = this.$store.getters.getTeamById(parseInt(kickerId))
            if(team != null) {
              if(this.$i18n.locale === 'de' && team.short !== null && team.short.length > 0) {
                return team.short
              } else {
                return team.short_int
              }
            }
            return ''
          },
          getTeamNameById(kickerId) {
            const team = this.$store.getters.getTeamById(parseInt(kickerId))
            if(team != null) {
              if(this.$i18n.locale === 'de' && team.short !== null && team.short.length > 0) {
                return team.short
              } else {
                return team.short_int
              }
            }
            return ''
          },
          contactAdmin() {
            this.setBGValue('spmbpc', 1)
            var mail = document.createElement('a')
            mail.href = 'mailto:' + encodeURIComponent(this.$store.state.betgame.email) + '?subject=' +
                this.$i18n.t('betgame') + encodeURIComponent(' ') + 
                encodeURIComponent(this.$store.state.betgame.name)
            mail.target = '_blank'
            mail.click()
          },
          setCheckBetgamePointsClicked(val) {
            this.setBGValue('spmbpc', val)
          },
          setEnterCodeClicked(val) {
            this.setBGValue('spmvr', val)
          },
          createBetgame() {
            this.hideRueckrundenAdClicked = true
            window.localStorage.setItem('hideRueckrundenAdClicked', 1)
            this.$gtag.event('EigeneTipprunde', {
              'event_category': 'createBetgame',
              'event_label': 'teamtip.dashboard',
              'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
              'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
              'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set',
              'dimension5': this.$store.state.betgame && this.$store.state.betgame.type ? this.$store.state.betgame.type : 'betgame type not set'
            })
            this.$router.push('/createuserbetgame')
          },
          copyBetgame() {
            this.$gtag.event('TipprundeKopieren', {
              'event_category': 'copyBetgame',
              'event_label': 'teamtip.dashboard',
              'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
              'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
              'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set',
              'dimension5': this.$store.state.betgame && this.$store.state.betgame.type ? this.$store.state.betgame.type : 'betgame type not set'
            })
            this.$router.push({ name: 'betgamecopy', params: { betgame: this.$store.state.betgame, action: 'copy' } })
          },
          create202425Betgame() {
            this.hide202425AdClicked = true
            window.localStorage.setItem('hide202425AdClicked', 1)
            this.$gtag.event('EigeneTipprunde', {
              'event_category': 'create202425Betgame',
              'event_label': 'teamtip.dashboard',
              'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
              'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
              'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set',
              'dimension5': this.$store.state.betgame && this.$store.state.betgame.type ? this.$store.state.betgame.type : 'betgame type not set'
            })
            this.$router.push('/createuserbetgame')
          },
          createBattle() {
            this.hideRueckrundenAdClicked = true
            window.localStorage.setItem('hideRueckrundenAdClicked', 1)
            this.$gtag.event('EigeneTipprunde', {
              'event_category': 'createBetgame',
              'event_label': 'teamtip.dashboard',
              'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
              'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
              'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set',
              'dimension5': this.$store.state.betgame && this.$store.state.betgame.type ? this.$store.state.betgame.type : 'betgame type not set'
            })
            this.$router.push('/createuserbetgame?type=2')
          },
          showRankings() {
            this.$router.push('/rankings')
          },
          visibilityChange(evt, hidden) {
            Vue.$log.debug('Dashboard visibilityChange - hidden -> ' + hidden)
            if(hidden === false && this.$store.state.leagueId) {
              this.$store.dispatch('fetchSchedule', this.$store.state.leagueId).then(() => {
                this.componentKey += 1
              })
              if(this.$store.state.betgameId) {
                const lastFetchSchedule = this.getBGValue('spmlflr')
                const duration2 = (new Date() - new Date(lastFetchSchedule)) / 1000
                const cachingDuration = Math.min(this.$store.getters.getCachingDuration, 60)
                Vue.$log.debug('Dashboard visibilityChange - duration2 -> ' + duration2 + ', cachingDuration -> ' + cachingDuration)
                if(lastFetchSchedule !== null && duration2 > cachingDuration) {
                  this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
                    this.setBGValue('spmlflr', new Date())
                    this.componentKey += 1
                  })
                }
              }
              if(this.isApp()) {
                window.location.reload(true)
              }
              const sentLastActive = this.getBGValue('spmsrh')
              const sentLastActiveSeconds = (new Date() - new Date(sentLastActive)) / 1000
              if(sentLastActiveSeconds > 10) {
                this.showRefreshHint = true
                if(sentLastActiveSeconds > 60 * 60 * 24) {
                  this.lastRefreshText = this.$t('dashboard.last_refresh_text') + ' ' + Math.floor(sentLastActiveSeconds / (60 * 60 * 24)) + ' ' + this.$t('dashboard.countdown_days')
                } else if(sentLastActiveSeconds > 60 * 60) {
                  this.lastRefreshText = this.$t('dashboard.last_refresh_text') + ' ' + Math.floor(sentLastActiveSeconds / (60 * 60)) + ' ' + this.$t('dashboard.countdown_hours')
                } else if(sentLastActiveSeconds > 60) {
                  this.lastRefreshText = this.$t('dashboard.last_refresh_text') + ' ' + Math.floor(sentLastActiveSeconds / 60) + ' ' + this.$t('dashboard.countdown_minutes')
                } else if(sentLastActiveSeconds > 10) {
                  this.lastRefreshText = this.$t('dashboard.last_refresh_text') + ' ' + Math.floor(sentLastActiveSeconds) + ' ' + this.$t('dashboard.countdown_seconds')
                }
              } else {
                this.showRefreshHint = false
              }
              /*
              const lastFetchSchedule = this.getBGValue('spmlfs')
              const duration2 = (new Date() - new Date(lastFetchSchedule)) / 1000
              const cachingDuration = Math.min(this.$store.getters.getCachingDuration, 60)
              if((lastFetchSchedule !== null && duration2 > cachingDuration) || 
                  this.$store.state.matches == null || this.$store.state.matches.length === 0) {
                this.$store.dispatch('fetchSchedule', this.$store.state.leagueId).then(() => {
                  this.componentKey += 1
                })
                if(this.$store.state.betgameId) {
                  this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
                    this.componentKey += 1
                  })
                }
              }
              */
            }
          },
          deepLinkClicked() {
            if(this.deepLink) {
              this.$gtag.event('Kombiwette', {
                'event_category': 'bwin',
                'event_label': this.matchday ? '' + this.matchday + '. Spieltag' : '',
                'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
                'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
                'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set',
                'dimension5': this.$store.state.betgame && this.$store.state.betgame.type ? this.$store.state.betgame.type : 'betgame type not set'
              })
              if(window.parent) {
                window.parent.open(this.deepLink, '_blank')
              } else {
                window.open(this.deepLink, '_blank')
              }
            }
          },
          openMenu() {
            this.$modal.show('betgame-menu')
          }
        }
    }
</script>

<style lang="scss">

    @keyframes live {
        0%   { opacity: .48; }
        50%  { opacity: 1; }
        100% { opacity: .48; }
    }

    small {
      display: flex;
      justify-content: center;
      font-size: 14px;
      color: $colorGreyMid;
      text-align: center;
      padding: 4px 0;
    }

    .dashboard {
        max-width: 768px;
        margin: 0px auto;

        @media screen and (min-width: 768px) {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: flex-start;
        }

        &-container {
            background: $colorWhite;
            box-shadow: 0 8px 24px 0 rgba(49,49,55,0.08);
            border-radius: 11px;
            overflow: hidden;
            margin: 0 0 24px;
            margin-top: 16px;

            @media screen and (min-width: 768px) {
                flex: 0 calc(50% - 12px);

                &.fullwidth {
                    flex: calc(50% - 12px);
                    margin: 0 auto 24px;
                    max-width: 576px;
                }
            }
        }

        &-image {
            display: flex;
            justify-content: center;
            border-bottom: 1px solid rgba($colorGreyLight, .08);
            position: relative;

            .matchdayWon &, .leaguePaused & {
                background: rgba(#F5B842, .08);
            }

            .live & {
                background: rgba($colorNegative, .08);

                img {
                    animation: live 2.5s infinite;
                }

                &:before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: url('../assets/icons/ic_dashboard_live_bg.svg');
                    background-position: center center;
                }
            }

            span {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                position: absolute;
                color: $colorWhite;
                font-size: 28px;
                font-weight: 500;
            }
        }

        &-countdown {
            width: 100%;
            padding: 35px 16px 32px 16px;
            border-bottom: 1px solid rgba($colorGreyLight, .08);
            background: rgba($colorGreyLight, .04);

            &--value {
                font-size: 48px;
                line-height: 40px;
                font-weight: 500;
                color: $colorGreyDark;
                width: 25%;
                text-align: center;
                overflow: hidden;
            }

            &--label {
                font-size: 17px;
                color: $colorGreyMid;
                width: 25%;
                text-align: center;
                overflow: hidden;
            }

            .last_minute & {
                background: rgba($colorNegative, .12);
            }
        }

        &-text {
            padding: 26px 16px 16px 16px;
            text-align: center;

            h1 {
                .last_minute & {
                    color: $colorNegative;
                }
            }

            button {
                margin-top: 26px;
            }

            span {
              font-weight: 600;
            }
        }

        &-ranking {
          margin-top: 24px;

          &__item {
            display: flex;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            color: $colorGreyDark;
            padding: 12px 16px 12px 8px;
            box-shadow: 0 -1px 0 0 rgba($colorGreyDark, .08);

            &__position {
              width: 24px;
              text-align: center;
              margin-right: 8px;
            }

            &__name {
              display: flex;
              flex: 1;
            }

            &__points {
              text-align: right;
              font-weight: 400;
            }
          }

          button {
            margin: 8px 16px 16px;
            width: calc(100% - 32px);
          }
        }

        .table-view-header {
          border-bottom: 0;
        }

        @media screen and (min-width: 768px) {
            
            #bg_rankings_table, #betgame_users_table {
                flex: 0 calc(50% - 12px);
                max-width: calc(50% - 12px);
                margin: 24px 0;
                overflow: hidden;

              .table {
                  width: 100%;
                  margin: 0 0 24px;
                  background: $colorWhite;
                  box-shadow: 0 8px 24px 0 rgba(49,49,55,0.08);
                  border-radius: 11px;
                  padding-bottom: 0;
                  overflow: hidden;
              }
            }

            .table-view {
              flex: 0 100%;
              margin: 24px 0 0 0;

              &:last-child {
                margin: 24px 0 48px;
              }
            }
        }
    }
    
    .table_background {
        background: rgba(208,208,208,.08);
    }

    .bwin_button {
        background: black;
        font-size: 17px;
        font-weight: 500;
        border: 0;
        border-radius: 6px;
        padding: 6px 10px;
        a {
            color: white;
            text-decoration: none;
        }
    }
    .bet-box-infotext {
        font-size: 9px;
        color: $colorGreyMid;
        text-align: center;
        width: 100%;
        margin-top: 3px;
    }
    .bwin_ad_marker {
        position: relative;
        float: right;
        left: 30px;
        font-size: 9px;
        color: rgba($colorGreyLight, .18)
    }

    .liveticker {
      background: $colorWhite;
      border-radius: 11px;
      width: 100%;
      padding: 0 16px;
      box-sizing: border-box;
      margin-top: 16px;

      @media screen and (min-width: 768px) {
        margin-top: 24px;
      }

      table {
        display: flex;
        flex-direction: column;
        border-collapse: collapse; 
        width: 100%;
      }

      td {
        padding: 0;
      }

      &__header {
        display: flex;
        align-items: center;
        padding: 16px 0 4px;
        font-size: 17px;
        font-weight: 500;
        color: $colorGreyDark;

        &__button {
          margin-left: auto;
        }
      }



      &__meta {
        display: flex;
        font-size: 11px;
        font-weight: 600;
        color: $colorGreyMid;
      }

      &__time {
        display: flex;
        align-items: center;
        color: $colorGreyDark;
        padding: 12px 0 4px !important;

        img {
          margin-right: 8px;
        }
      }

      &__teams {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: flex-end;
        white-space: nowrap;
        padding: 12px 0 4px !important;

        span {
          color: $colorGreyDark;
          margin: 0 2px;
        }

        img {
          width: 18px;
          height: 18px;
        }
      }

      &__text {
        display: flex;
        flex: 1;
        font-size: 14px;
        line-height: 20px;
        color: $colorGreyDark;

        td {
          padding: 4px 0 12px;
          width: 100%;
        }

        &:not(:last-child) {
          td {
            border-bottom: 1px solid rgba($colorGreyLight, .08);
          }
        }
      }
    }

    .adbanner {
      position: relative;
      border-radius: 11px;
      background: $colorWhite;
      box-shadow: 0 4px 8px 0 rgba($colorGreyDark, .04), 0 0 0 1px rgba($colorGreyDark, .16);
      margin: 16px auto 0px;
      max-width: 458px;
      overflow: hidden;
      cursor: pointer;

      img {
        width: 100%;
        display: flex;
      }

      &__close {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 24px;
        height: 24px;
        background: rgba($colorWhite, .8);
        border-radius: 6px;
        box-shadow: 0 0 0 1px rgba($colorGreyDark, .08);
        transition: all .2s ease-in-out;

        &:hover {
          cursor: pointer;
          box-shadow: 0 0 0 1px rgba($colorGreyDark, .16);
          background: $colorWhite;
        }

        &:before {
          content: '';
          position: absolute;
          width: 2px;
          height: 10px;
          top: calc(50% - 5px);
          left: calc(50% - 1px);
          background: $colorGreyDark;
          transform: rotate(45deg);
        }

        &:after {
          content: '';
          position: absolute;
          width: 10px;
          height: 2px;
          top: calc(50% - 1px);
          left: calc(50% - 5px);
          background: $colorGreyDark;
          transform: rotate(45deg);
        }
      }
    }    
</style>
