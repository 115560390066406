<template>
	<div v-if="matchdayPoints.length > 0" class="table-view trend-box">
      <div class="table-view-header">
        {{ $t('dashboard.my_shape_curve') }}
      </div>
      <div class="list">
        <div v-for="(data, index) in matchdayPoints" :key="index" class="trend-box-item" v-bind:class="{ 'trend_star' : data.win === true, 'trend_positive' : data.win === false && data.points >= 9, 'trend_middle' : data.win === false && data.points < 9 && data.points >= 5, 'trend_negative' : data.win == false && data.points < 5 }">
          <div v-if="data.points > 0" class="trend-box-bar" v-bind:style="'height: calc((100% - 50px) * ' + data.points/maxPoints + ')'">{{ data.points }} {{ $t('rankings_table_header_points') }}.</div>
          <div v-else-if="data.points === 0" class="trend-box-bar-zero-points">{{ data.points }} {{ $t('rankings_table_header_points') }}.</div>
          <div class="trend-box-label">{{ matchdayText(data.matchday) }}</div>
        </div>
        <!--
        <div class="trend-box-item trend_star">
          <div class="trend-box-bar">16 Pkt.</div>
          <div class="trend-box-label">12. Spieltag</div>
        </div>
        <div class="trend-box-item trend_negative">
          <div class="trend-box-bar">4 Pkt.</div>
          <div class="trend-box-label">11. Spieltag</div>
        </div>
        <div class="trend-box-item trend_positive">
          <div class="trend-box-bar">13 Pkt.</div>
          <div class="trend-box-label">10. Spieltag</div>
        </div>
        <div class="trend-box-item trend_middle">
          <div class="trend-box-bar">10. Pkt.</div>
          <div class="trend-box-label">9. Spieltag</div>
        </div>
        <div class="trend-box-item trend_positive">
          <div class="trend-box-bar">13 Pkt.</div>
          <div class="trend-box-label">8. Spieltag</div>
        </div>
        <div class="trend-box-item trend_middle">
          <div class="trend-box-bar">10. Pkt.</div>
          <div class="trend-box-label">7. Spieltag</div>
        </div>
        -->
      </div>
    </div>
</template>

<script>
  	import { isNumeric } from '../vuex/helpers'
  
    export default {
  	  name: 'ProfileTrendbox',
      data () {
        return {
          maxPoints: 8
        }
      },
      computed: {
        matchdayPoints () {
          var result = []
          const bets = this.$store.state.bets
          const md = this.$store.state.currentMatchdayRankingByMatchday > 0 ? this.$store.state.currentMatchdayRankingByMatchday : 1
          for (var matchday = md; matchday > 0 && result.length < 6; matchday--) {
            var points = 0
            const matchdayMatches = this.$store.getters.getMatchesByMatchday(matchday)
            if (matchdayMatches.length > 0) {
              for (var i = 0; i < matchdayMatches.length; i++) {
                const match = matchdayMatches[i]
                if((!isNumeric(match.goalshome) || !isNumeric(match.goalsguest)) && 
                    (!isNumeric(match.livegoalshome) || !isNumeric(match.livegoalsguest))) {
                  continue
                }
                const betMatches = bets.filter(betMatch => betMatch.fk_match === match.id)
                if (betMatches.length === 1) {
                  const p = this.getPoints(betMatches[0], match)
                  if(p > 0) {
                    points += p
                  }
                }
              }
            }
            if(points > 0) {
              if(points > this.maxPoints) {
                this.maxPoints = points
              }
              const mdw = this.$store.getters.getMatchdayWin(this.$store.state.user.id, matchday)
              result.push( {matchday: matchday, points: points, win: mdw.length === 1 } )
            } else {
              result.push( {matchday: matchday, points: 0, win: false } )
            }
          }
          return result
        }
      },
      methods: {
        matchdayText(matchday) {
          if(matchday === undefined || matchday === null || matchday < 1) {
            return ''
          }
          if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 19) {
            if(matchday === 7) {
              return this.$i18n.t('ko_round_of_16_first_legs')
            } else if(matchday === 8) {
              return this.$i18n.t('ko_round_of_16_second_legs')
            } else if(matchday === 9) {
              return this.$i18n.t('ko_round_quarter_finals_first_legs')
            } else if(matchday === 10) {
              return this.$i18n.t('ko_round_quarter_finals_second_legs')
            } else if(matchday === 11) {
              return this.$i18n.t('ko_round_semi_finals_first_legs')
            } else if(matchday === 12) {
              return this.$i18n.t('ko_round_semi_finals_second_legs')
            } else if(matchday === 13) {
              return this.$i18n.t('ko_round_final')
            }
          } else if(this.$store.state.betgame && this.$store.state.betgame.competition &&
              (this.$store.state.betgame.competition.id === 132 || this.$store.state.betgame.competition.id === 6395)) {
            if(matchday === 7) {
              return this.$i18n.t('ko_round_playoffs_first_legs')
            } else if(matchday === 8) {
              return this.$i18n.t('ko_round_playoffs_second_legs')
            } else if(matchday === 9) {
              return this.$i18n.t('ko_round_of_16_first_legs')
            } else if(matchday === 10) {
              return this.$i18n.t('ko_round_of_16_second_legs')
            } else if(matchday === 11) {
              return this.$i18n.t('ko_round_quarter_finals_first_legs')
            } else if(matchday === 12) {
              return this.$i18n.t('ko_round_quarter_finals_second_legs')
            } else if(matchday === 13) {
              return this.$i18n.t('ko_round_semi_finals_first_legs')
            } else if(matchday === 14) {
              return this.$i18n.t('ko_round_semi_finals_second_legs')
            } else if(matchday === 15) {
              return this.$i18n.t('ko_round_final')
            }
          } else if(this.$store.state.betgame && this.$store.state.betgame.competition && 
              (this.$store.state.betgame.competition.id === 10700 || this.$store.state.betgame.competition.id === 36)) {
            if(matchday === 4) {
              return this.$i18n.t('em_ko_round_of_16')
            } else if(matchday === 5) {
              return this.$i18n.t('em_ko_round_quarter_finals')
            } else if(matchday === 6) {
              return this.$i18n.t('em_ko_round_semi_finals')
            } else if(matchday === 7) {
              return this.$i18n.t('ko_round_final')
            }
          } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 533) {
            const nextMatchday = matchday
            if(nextMatchday === 1) {
              return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
            } else if(nextMatchday === 2) {
              return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
            } else if(nextMatchday === 3) {
              return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
            } else if(nextMatchday === 8) {
              return this.$i18n.t('ko_round_fifth_place_short')
            } else if(nextMatchday === 9) {
              return this.$i18n.t('em_ko_round_semi_finals')
            } else if(nextMatchday === 10) {
              return this.$i18n.t('ko_round_third_place_short')
            } else if(nextMatchday === 11) {
              return this.$i18n.t('ko_round_final')
            }
          } else if(this.$store.state.betgame && this.$store.state.betgame.competition && 
              (this.$store.state.betgame.competition.id === 139 || this.$store.state.betgame.competition.id === 1110)) {
            const nextMatchday = matchday
            if(nextMatchday === 1) {
              return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
            } else if(nextMatchday === 2) {
              return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
            } else if(nextMatchday === 3) {
              return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
            } else if(nextMatchday === 4) {
              return this.$i18n.t('em_ko_round_of_16')
            } else if(nextMatchday === 5) {
              return this.$i18n.t('em_ko_round_quarter_finals')
            } else if(nextMatchday === 6) {
              return this.$i18n.t('em_ko_round_semi_finals')
            } else if(nextMatchday === 7) {
              return this.$i18n.t('ko_round_third_place_short')
            } else if(nextMatchday === 8) {
              return this.$i18n.t('ko_round_final')
            }
          } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 1180) {
            const nextMatchday = matchday
            if(nextMatchday === 1) {
              return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
            } else if(nextMatchday === 2) {
              return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
            } else if(nextMatchday === 3) {
              return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
            } else if(nextMatchday === 4) {
              return this.$i18n.t('em_ko_round_quarter_finals')
            } else if(nextMatchday === 5) {
              return this.$i18n.t('em_ko_round_semi_finals')
            } else if(nextMatchday === 6) {
              return this.$i18n.t('ko_round_final')
            }
          }
          return this.$i18n.t('matchday', { matchday: matchday })
        },
        getPoints(bet, match) {
          if(bet === null || match === null) {
            return -1
          }
          if(!this.isNumeric(match.goalshome) && !this.isNumeric(match.goalsguest) &&
                  !this.isNumeric(match.livegoalshome) && !this.isNumeric(match.livegoalsguest)) {
            return -1
          }
          var goalshome = null
          var goalsguest = null
          if(match.livegoalshome !== null && match.livegoalsguest !== null &&
                  match.goalshome === null && match.goalsguest === null) {
            goalshome = match.livegoalshome
            goalsguest = match.livegoalsguest
          } else if(match.goalshome !== null && match.goalsguest !== null) {
            goalshome = match.goalshome
            goalsguest = match.goalsguest
          }
          if(goalshome !== null && goalsguest !== null) {
            if(!this.$store.state.betgame.bet_mode || this.$store.state.betgame.bet_mode === 1) {
              if ((bet.goalshome === goalshome) && (bet.goalsguest === goalsguest)) {
                return this.$store.getters.pointsBetResult
              } else if ((bet.goalshome - bet.goalsguest) === (goalshome - goalsguest)) {
                return this.$store.getters.pointsBetGoaldiff
              } else if ((bet.goalshome > bet.goalsguest) && (goalshome > goalsguest)) {
                return this.$store.getters.pointsBetTendency
              } else if ((bet.goalshome < bet.goalsguest) && (goalshome < goalsguest)) {
                return this.$store.getters.pointsBetTendency
              }
            } else if(this.$store.state.betgame.bet_mode === 2) {
              if ((bet.goalshome === 1) && (goalshome > goalsguest)) {
                return this.$store.getters.pointsBetResult
              } else if ((bet.goalshome === 0) && (goalshome === goalsguest)) {
                return this.$store.getters.pointsBetResult
              } else if ((bet.goalshome === 2) && (goalshome < goalsguest)) {
                return this.$store.getters.pointsBetResult
              }
            }
          }
          return 0
        }
      }
	}
</script>

<style lang="scss" scoped>
.trend-box {

  .table-view-header {
    border-bottom: 1px solid transparent;
  }

  .list {
    display: flex;
    justify-content: space-between;
    height: 160px;
    box-shadow: 0 8px 24px 0 rgba(49,49,55,0.08);
    padding-top: 24px;
    flex-direction: row-reverse;
  }

  &-item {
    display: flex;
    flex-direction: column;
    flex: 0 1 25%;
    justify-content: flex-end;
    align-items: center;

    @media screen and (max-width: 440px) {
      &:nth-child(n+5) {
        display: none;
      }
    }
  }
  
  &-bar-zero-points {
    width: 100%;
    max-width: 48px;
    font-size: 11px;
    font-weight: 500;
    color: #ACACAC;
    text-align: center;
    padding: 5px 0 0;
    border-radius: 4px;
    min-height: 18px;
  }
  
  &-bar {
    width: 100%;
    max-width: 48px;
    height: 100%;
    font-size: 11px;
    font-weight: 500;
    color: $colorWhite;
    text-align: center;
    padding: 5px 0 0;
    border-radius: 4px;
    min-height: 18px;

    .trend_positive & {
      background: $colorPositive;
    }
    .trend_middle & {
      background: $colorGreyMid;
    }
    .trend_negative & {
      background: $colorNegative;
    }
    .trend_star & {
      background: $colorYellow;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: 32px;
        height: 32px;
        top: -36px;
        left: 50%;
        transform: translateX(-50%);
        background: url('../assets/icons/ic_32_star.svg');
      }
    }
  }

  &-label {
    font-size: 11px;
    font-weight: 500;
    color: $colorGreyMid;
    margin-top: 6px;
  }
}
</style>
