<template>
  <div class="feed">

    <keep-alive>
      <NavigationBar id="rulesNavigationBarId" ref="rulesNavigationBarRef">
        <template v-slot:center>
          {{ betgameName }}
        </template>
      </NavigationBar>
    </keep-alive>

    <!-- Tabbar (for Dashboard & Feed) -->
    <div class="navigation__tabbar">
      <div v-on:click="tabChanged('dashboard')"  class="navigation__tabbar__item" v-bind:class="this.selectedTab === 'dashboard' ? 'navigation__tabbar__item--active' : ''">
        <div class="navigation__tabbar__item__label">
          Home
        </div>
      </div>
      <div v-on:click="tabChanged('chat')" class="navigation__tabbar__item" v-bind:class="this.selectedTab === 'chat' ? 'navigation__tabbar__item--active' : ''">
        <div class="navigation__tabbar__item__label">
          Chat
          <span v-if="this.$store.state.unreadCount && this.$store.state.unreadCount > 0">{{ this.$store.state.unreadCount }}</span>
        </div>
      </div>
    </div>
    
    <Dashboard v-if="this.selectedTab === 'dashboard'"></Dashboard>
    <Chat v-else-if="this.selectedTab === 'chat'"></Chat>
    
  </div>
</template>

<script>

import NavigationBar from '../components/NavigationBar'
import Vue from 'vue'
import Dashboard from './DashboardNew.vue'
import Chat from '@/components/Chat.vue'

export default {
  name: 'dashboard',
  components: { Chat, Dashboard, NavigationBar },
  data () {
    return {
      selectedTab: 'dashboard'
    }
  },
  beforeCreate() {
    Vue.$log.debug('Feed beforeCreate ' + this.$router.currentRoute.name)
  },
  created() {
    Vue.$log.debug('Feed created ' + this.$route.params.tab + ' ---' + this.$router.currentRoute.name)
    if(this.$router.currentRoute && this.$router.currentRoute.name && this.$router.currentRoute.name === 'chatview') {
      this.selectedTab = 'chat'
    } else if(this.$router.currentRoute && this.$router.currentRoute.name && this.$router.currentRoute.name.includes('dashboardold')) {
      this.selectedTab = 'dashboard'
    } else if(this.$route.params && this.$route.params.tab && this.$route.params.tab.length > 0) {
      this.selectedTab = this.$route.params.tab
    }
    Vue.$log.debug('Feed created selectedTab ' + this.selectedTab)
  },
  mounted() {
    Vue.$log.debug('Feed mounted')
  },
  beforeDestroy () {

  },
  methods: {
    tabChanged(tab) {
      Vue.$log.debug('Feed.vue tabChanged ' + tab)
      this.selectedTab = tab
      if(this.selectedTab === 'dashboard') {
        window.scrollTo(0,0)
      } else if(this.selectedTab === 'chat') {
        setTimeout(() => {
          const e = document.getElementById('1')
          if(e) {
            e.scrollIntoView()
          }
          if(this.$store.state.chat_items && this.$store.state.chat_items.length < 10) {
            this.$store.dispatch('setUnreadCount', 0)
          }
        }, 500)
      }
    },

  },
  computed: {
    betgameName() {
      if(this.$store.state.betgame) {
        return this.$store.state.betgame.name
      } else {
        if(this.getBGValue('spmbgid') && parseInt(this.getBGValue('spmbgid')) > 0) {
          this.$store.dispatch('fetchBetgame', this.getBGValue('spmbgid'))
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .feed {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0px auto;
    min-height: 100%;
    box-sizing: border-box;
  }

  .navigation__tabbar {
    position: sticky;
    top: 63px;
    z-index: 500;
    display: flex;
    justify-content: center;
    background: $colorWhite;
    width: 100vw;
    margin: -1px -16px 0;
    box-shadow: inset 0 -1px rgba($colorGreyDark, .24);

    &__item {
      display: flex;
      justify-content: center;
      width: calc(768px / 2);
      padding: 12px 0;
      box-sizing: border-box;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: $colorGreyDark;
      cursor: pointer;
      text-decoration: none;

      &:not(.navigation__tabbar__item--active):hover {
        color: rgba(0,0,0,0.8);
      }

      &--active {
        color: var(--color-primary);
        box-shadow: inset 0 -2px 0 0 var(--color-primary);
      }

      &__label {
        position: relative;

        span {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          content: '';
          top: -10px;
          right: -16px;
          min-width: 16px;
          height: 16px;
          padding: 0.5px 0 0 1px;
          border-radius: 8px;
          font-size: 10px;
          font-weight: 500;
          color: $colorWhite;
          background: $colorLive;
          box-sizing: border-box;
        }
      }
    }
  }
</style>
