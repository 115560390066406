import { render, staticRenderFns } from "./EditBetRow.vue?vue&type=template&id=70cd7d0a&scoped=true"
import script from "./EditBetRow.vue?vue&type=script&lang=js"
export * from "./EditBetRow.vue?vue&type=script&lang=js"
import style0 from "./EditBetRow.vue?vue&type=style&index=0&id=70cd7d0a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70cd7d0a",
  null
  
)

export default component.exports