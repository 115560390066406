import { render, staticRenderFns } from "./BetgameDescription.vue?vue&type=template&id=152cf2e0&scoped=true"
import script from "./BetgameDescription.vue?vue&type=script&lang=js"
export * from "./BetgameDescription.vue?vue&type=script&lang=js"
import style0 from "./BetgameDescription.vue?vue&type=style&index=0&id=152cf2e0&prod&lang=scss"
import style1 from "./BetgameDescription.vue?vue&type=style&index=1&id=152cf2e0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "152cf2e0",
  null
  
)

export default component.exports