<template>
  <div class="selectmatches" id="selectmatches" :key="componentKey">
    <div v-if="this.betgameType === 'betgame'" class="input-container">
      <div class="input-header">
        {{ $t('create_betgame.select_competition') }}
      </div>
      <!--
      <div class="input-radio">
        <input v-on:change="competitionSelected" v-model="selectedCompetition" type="radio" id="wettbewerb-13" name="wettbewerb" value="533" />
        <label for="wettbewerb-13">Handball EM 2022</label>
        <label v-else for="wettbewerb-7" style="color: lightgray">SüperLig 2022/23</label>
      </div>
      -->
      <!--
      <div class="input-radio">
        <input v-on:change="competitionSelected" :checked="selectedCompetition === '36'" type="radio" id="wettbewerb-36" name="wettbewerb" value="36" />
        <label for="wettbewerb-36">EURO 2024</label>
      </div>
      -->
      
      <!-- Deutschland -->
      <div>
        <small>{{ $t('create_betgame.german_competitions') }}</small>
        <div class="input-radio">
          <input v-on:change="competitionSelected" :checked="selectedCompetition === '1'" type="radio" id="wettbewerb-2" name="wettbewerb" value="1" />
          <label for="wettbewerb-2">1. Bundesliga</label>
          <!-- <label for="wettbewerb-2" style="color: lightgray">1. Bundesliga 2024/25</label> -->
        </div>
        <div class="input-radio">
          <input v-on:change="competitionSelected" :checked="selectedCompetition === '2'" type="radio" id="wettbewerb-3" name="wettbewerb" value="2" />
          <label for="wettbewerb-3">2. Bundesliga</label>
          <!-- <label for="wettbewerb-3" style="color: lightgray">2. Bundesliga 2024/25</label> -->
        </div>
        <div class="input-radio">
          <input v-on:change="competitionSelected" :checked="selectedCompetition === '3'" type="radio" id="wettbewerb-1" name="wettbewerb" value="3" />
          <label for="wettbewerb-1">3. Liga</label>
          <!-- <label for="wettbewerb-1" style="color: lightgray">3. Liga 24/25</label> -->
        </div>
        <div class="input-radio">
          <input v-on:change="competitionSelected" :checked="selectedCompetition === '564'" type="radio" id="wettbewerb-564" name="wettbewerb" value="564" />
          <label for="wettbewerb-564">Regionalliga West</label>
          <!-- <label for="wettbewerb-564" style="color: lightgray">Regionalliga West 24/25</label> -->
        </div>
        <div class="input-radio">
          <input v-on:change="competitionSelected" :checked="selectedCompetition === '107'" type="radio" id="wettbewerb-107" name="wettbewerb" value="107" />
          <label for="wettbewerb-107">Regionalliga Nord</label>
          <!-- <label for="wettbewerb-107" style="color: lightgray">Regionalliga Nord 24/25</label> -->
        </div>
        <div class="input-radio">
          <input v-on:change="competitionSelected" :checked="selectedCompetition === '2257'" type="radio" id="wettbewerb-2257" name="wettbewerb" value="2257" />
          <label for="wettbewerb-2257">Regionalliga Bayern</label>
          <!-- <label for="wettbewerb-2257" style="color: lightgray">Regionalliga Bayern 2024/25</label> -->
        </div>
        <div class="input-radio">
          <input v-on:change="competitionSelected" :checked="selectedCompetition === '2255'" type="radio" id="wettbewerb-2255" name="wettbewerb" value="2255" />
          <label for="wettbewerb-2255">Regionalliga Nordost</label>
          <!-- <label for="wettbewerb-2255" style="color: lightgray">Regionalliga Nordost 24/25</label> -->
        </div>
        <div class="input-radio">
          <input v-on:change="competitionSelected" :checked="selectedCompetition === '2256'" type="radio" id="wettbewerb-2256" name="wettbewerb" value="2256" />
          <label for="wettbewerb-2256">Regionalliga Südwest</label>
          <!-- <label for="wettbewerb-2256" style="color: lightgray">Regionalliga Südwest</label> -->
        </div>
        <div class="input-radio">
          <input v-on:change="competitionSelected" :checked="selectedCompetition === '14'" type="radio" id="wettbewerb-14" name="wettbewerb" value="14" />
          <label for="wettbewerb-14">{{ $t('create_betgame.competition_bundesliga_women') }}</label>
          <!-- <label for="wettbewerb-14" style="color: lightgray">{{ $t('create_betgame.competition_bundesliga_women') }}</label> -->
        </div>
        <div class="input-radio">
          <input v-on:change="competitionSelected" :checked="selectedCompetition === '33'" type="radio" id="wettbewerb-18" name="wettbewerb" value="33" />
          <label for="wettbewerb-18">DFB-Pokal</label>
          <!-- <label for="wettbewerb-18" style="color: lightgray">DFB-Pokal 24/25</label> -->
        </div>
      </div>

      <!-- UEFA -->
      <div>
        <small>UEFA</small>
        <div class="input-radio">
          <input v-on:change="competitionSelected" :checked="selectedCompetition === '19'" type="radio" id="wettbewerb-9" name="wettbewerb" value="19" disabled />
          <!-- <label for="wettbewerb-9">Champions League</label> -->
          <label for="wettbewerb-9" style="color: lightgray">Champions League</label>
        </div>
        <div class="input-radio">
          <input v-on:change="competitionSelected" :checked="selectedCompetition === '132'" type="radio" id="wettbewerb-16" name="wettbewerb" value="132" disabled />
          <!-- <label for="wettbewerb-16">Europa League</label> -->
          <label for="wettbewerb-16" style="color: lightgray">Europa League</label>
        </div>
        <div class="input-radio">
          <input v-on:change="competitionSelected" :checked="selectedCompetition === '6395'" type="radio" id="wettbewerb-19" name="wettbewerb" value="6395" disabled />
          <!-- <label for="wettbewerb-19">Europa Conference League</label> -->
          <label for="wettbewerb-19" style="color: lightgray">Europa Conference League</label>
        </div>
      </div>
      
      <!-- International -->
      <div>
        <small>{{ $t('create_betgame.international_competitions') }}</small>
        <div class="input-radio">
          <input v-on:change="competitionSelected" :checked="selectedCompetition === '91'" type="radio" id="wettbewerb-5" name="wettbewerb" value="91" />
          <label for="wettbewerb-5">Premier League</label>
          <!-- <label for="wettbewerb-5" style="color: lightgray">Premier League 2024/25</label> -->
        </div>
        <div class="input-radio">
          <input v-on:change="competitionSelected" :checked="selectedCompetition === '97'" type="radio" id="wettbewerb-4" name="wettbewerb" value="97" />
          <label for="wettbewerb-4">La Liga</label>
          <!-- <label for="wettbewerb-4" style="color: lightgray">La Liga 2024/25</label> -->
        </div>
        <div class="input-radio">
          <input v-on:change="competitionSelected" :checked="selectedCompetition === '111'" type="radio" id="wettbewerb-6" name="wettbewerb" value="111" />
          <label for="wettbewerb-6">Serie A</label>
          <!-- <label for="wettbewerb-6" style="color: lightgray">Serie A 24/25</label> -->
        </div>
        <div class="input-radio">
          <input v-on:change="competitionSelected" :checked="selectedCompetition === '116'" type="radio" id="wettbewerb-7" name="wettbewerb" value="116" />
          <label for="wettbewerb-7">Trendyol SüperLig</label>
          <!-- <label for="wettbewerb-7" style="color: lightgray">Trendyol SüperLig 24/25</label> -->
        </div>
        <div class="input-radio">
          <input v-on:change="competitionSelected" :checked="selectedCompetition === '71'"  type="radio" id="wettbewerb-8" name="wettbewerb" value="71" />
          <label for="wettbewerb-8">Ligue 1</label>
          <!-- <label for="wettbewerb-8" style="color: lightgray">Ligue 1 2024/25</label> -->
        </div>
        <div class="input-radio">
          <input v-on:change="competitionSelected" :checked="selectedCompetition === '13'" type="radio" id="wettbewerb-11" name="wettbewerb" value="13" />
          <label for="wettbewerb-11">Admiral - Bundesliga</label>
          <!-- <label for="wettbewerb-11" style="color: lightgray">Admiral - Bundesliga 24/25 ({{ $t('create_betgame.austria') }})</label> -->
        </div>
        <div class="input-radio">
          <input v-on:change="competitionSelected" :checked="selectedCompetition === '115'" type="radio" id="wettbewerb-12" name="wettbewerb" value="115" />
          <label for="wettbewerb-12">Credit Suisse Super League</label>
          <!-- <label for="wettbewerb-12" style="color: lightgray">Super League 2024/25 ({{ $t('create_betgame.swiss') }})</label> -->
        </div>
        <div class="input-radio">
          <input v-on:change="competitionSelected" :checked="selectedCompetition === '76'" type="radio" id="wettbewerb-76" name="wettbewerb" value="76" />
          <label for="wettbewerb-76">OTP Bank Liga</label>
          <!-- <label for="wettbewerb-76" style="color: lightgray">OTP Bank Liga</label> -->
        </div>
      </div>
      
      <!-- Deutschland (Amateure) -->
      <div>
        <small>{{ $t('create_betgame.misc_competitions') }}</small>
        <div class="input-radio">
          <input v-on:change="competitionSelected" :checked="selectedCompetition === '4228'" type="radio" id="wettbewerb-4228" name="wettbewerb" value="4228" />
          <label for="wettbewerb-4228">Nations League</label>
          <!-- <label for="wettbewerb-4228" style="color: lightgray">Nations League</label> -->
        </div>
      </div>
    </div>

    <div v-else-if="this.betgameType === 'battle'" class="input-container">
      <div class="input-header">
        {{ $t('create_betgame.select_matches') }}
      </div>
      <div class="input-infotext">
        {{ $t('create_betgame.select_battle_matches') }}
      </div>
      <!--
      <div class="input-text" v-on:click="opemMatchSelect(36)">
        <label>EURO 2024<span v-bind:class="{'active' : selectedMatchesCount(36) !== 0}">{{ selectedMatchesCount(36) }}</span></label>
      </div>
      -->
      <div class="input-text" v-on:click="opemMatchSelect(1)">
        <label>1. Bundesliga<span v-bind:class="{'active' : selectedMatchesCount(1) !== 0}">{{ selectedMatchesCount(1) }}</span></label>
      </div>
      <div class="input-text" v-on:click="opemMatchSelect(2)">
        <label>2. Bundesliga<span v-bind:class="{'active' : selectedMatchesCount(2) !== 0}">{{ selectedMatchesCount(2) }}</span></label>
      </div>
      <div class="input-text" v-on:click="opemMatchSelect(3)">
        <label>3. Liga<span v-bind:class="{'active' : selectedMatchesCount(3) !== 0}">{{ selectedMatchesCount(3) }}</span></label>
      </div>
      <div class="input-text" v-on:click="opemMatchSelect(33)">
        <label>DFB-Pokal<span v-bind:class="{'active' : selectedMatchesCount(33) !== 0}">{{ selectedMatchesCount(33) }}</span></label>
      </div>
      <div class="input-text" v-on:click="opemMatchSelect(19)">
        <label>Champions League<span v-bind:class="{'active' : selectedMatchesCount(19) !== 0}">{{ selectedMatchesCount(19) }}</span></label>
      </div>
      <div class="input-text" v-on:click="opemMatchSelect(132)">
        <label>Europa League<span v-bind:class="{'active' : selectedMatchesCount(132) !== 0}">{{ selectedMatchesCount(132) }}</span></label>
      </div>
      <div class="input-text" v-on:click="opemMatchSelect(6395)">
        <label>Europa Conference League<span v-bind:class="{'active' : selectedMatchesCount(6395) !== 0}">{{ selectedMatchesCount(6395) }}</span></label>
      </div>
      <div class="input-text" v-on:click="opemMatchSelect(14)">
        <label>{{ $t('create_betgame.competition_bundesliga_women') }}<span v-bind:class="{'active' : selectedMatchesCount(14) !== 0}">{{ selectedMatchesCount(14) }}</span></label>
      </div>
      <div class="input-text" v-on:click="opemMatchSelect(91)">
        <label>Premier League<span v-bind:class="{'active' : selectedMatchesCount(91) !== 0}">{{ selectedMatchesCount(91) }}</span></label>
      </div>
      <div class="input-text" v-on:click="opemMatchSelect(97)">
        <label>La Liga<span v-bind:class="{'active' : selectedMatchesCount(97) !== 0}">{{ selectedMatchesCount(97) }}</span></label>
      </div>
      <div class="input-text" v-on:click="opemMatchSelect(111)">
        <label>Serie A<span v-bind:class="{'active' : selectedMatchesCount(111) !== 0}">{{ selectedMatchesCount(111) }}</span></label>
      </div>
      <div class="input-text" v-on:click="opemMatchSelect(115)">
        <label>{{ $t('create_betgame.competition_superleague_swiss') }}<span v-bind:class="{'active' : selectedMatchesCount(115) !== 0}">{{ selectedMatchesCount(115) }}</span></label>
      </div>
      <div class="input-text" v-on:click="opemMatchSelect(13)">
        <label>{{ $t('create_betgame.competition_bundesliga_austria') }}<span v-bind:class="{'active' : selectedMatchesCount(13) !== 0}">{{ selectedMatchesCount(13) }}</span></label>
      </div>
      <div class="input-text" v-on:click="opemMatchSelect(116)">
        <label>Trendyol SüperLig<span v-bind:class="{'active' : selectedMatchesCount(116) !== 0}">{{ selectedMatchesCount(116) }}</span></label>
      </div>
      <div class="input-text" v-on:click="opemMatchSelect(71)">
        <label>Ligue 1<span v-bind:class="{'active' : selectedMatchesCount(71) !== 0}">{{ selectedMatchesCount(71) }}</span></label>
      </div>
      <div class="input-text" v-on:click="opemMatchSelect(76)">
        <label>OTP Bank Liga<span v-bind:class="{'active' : selectedMatchesCount(76) !== 0}">{{ selectedMatchesCount(76) }}</span></label>
      </div>
      <div class="input-text" v-on:click="opemMatchSelect(107)">
        <label>Regionalliga Nord<span v-bind:class="{'active' : selectedMatchesCount(107) !== 0}">{{ selectedMatchesCount(107) }}</span></label>
      </div>
      <div class="input-text" v-on:click="opemMatchSelect(564)">
        <label>Regionalliga West<span v-bind:class="{'active' : selectedMatchesCount(564) !== 0}">{{ selectedMatchesCount(564) }}</span></label>
      </div>
      <div class="input-text" v-on:click="opemMatchSelect(2255)">
        <label>Regionalliga Nordost<span v-bind:class="{'active' : selectedMatchesCount(2255) !== 0}">{{ selectedMatchesCount(2255) }}</span></label>
      </div>
      <div class="input-text" v-on:click="opemMatchSelect(2256)">
        <label>Regionalliga Südwest<span v-bind:class="{'active' : selectedMatchesCount(2256) !== 0}">{{ selectedMatchesCount(2256) }}</span></label>
      </div>
      <div class="input-text" v-on:click="opemMatchSelect(2257)">
        <label>Regionalliga Bayern<span v-bind:class="{'active' : selectedMatchesCount(2257) !== 0}">{{ selectedMatchesCount(2257) }}</span></label>
      </div>
      <div class="input-text" v-on:click="opemMatchSelect(4228)">
        <label>Nations League<span v-bind:class="{'active' : selectedMatchesCount(4228) !== 0}">{{ selectedMatchesCount(4228) }}</span></label>
      </div>
      <!--
      <div class="input-text" v-on:click="opemMatchSelect(19)">
        <label>Champions League<span v-bind:class="{'active' : selectedMatchesCount(19) !== 0}">{{ selectedMatchesCount(19) }}</span></label>
      </div>
      <div class="input-text" v-on:click="opemMatchSelect(132)">
        <label>Europa League<span v-bind:class="{'active' : selectedMatchesCount(132) !== 0}">{{ selectedMatchesCount(132) }}</span></label>
      </div>
      <div class="input-text" v-on:click="opemMatchSelect(6395)">
        <label>Europa Conference League<span v-bind:class="{'active' : selectedMatchesCount(6395) !== 0}">{{ selectedMatchesCount(6395) }}</span></label>
      </div>
      <div class="input-text" v-on:click="opemMatchSelect(7)">
        <label>Europa Conference League<span v-bind:class="{'active' : selectedMatchesCount(7) !== 0}">{{ selectedMatchesCount(7) }}</span></label>
      </div>
      -->
    </div>

    <div v-else-if="this.betgameType === 'teams'" class="input-container">
      <div class="input-header">
        {{ $t('create_betgame.select_teams') }}
      </div>
      <div class="input-infotext">
        {{ $t('create_betgame.select_teams_description') }}
      </div>

      <div class="input-search">
        <input type="text" v-model="teamsFilter" name="teamsFilter" id="teamsFilter" placeholder="Team suchen...">
      </div>

      <div v-if="selectedTeamsList && selectedTeamsList.length > 0" class="selected-teams">
        <div v-for="team in selectedTeamsList" class="selected-teams__item">
          <div class="selected-teams__item__close" v-on:click="unselectTeam(team.id)">
            <div class="selected-teams__item__close__icon"></div>
          </div>
          <div class="team-logo">
            <img v-if="teamCode(team.id) && teamCode(team.id).length > 6" :src="teamLogo(team.id)" width="24">
            <div v-else-if="teamCode(team.id) && teamCode(team.id).length <= 6" class="flag">
              <flag :iso="teamCode(team.id)"/>
            </div>
            <svg v-else style="margin-right: 12px" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <path d="M16.6788228,14.4666528 C16.6784319,14.5535821 16.6782365,14.6380312 16.6782365,14.72 C16.6782365,17.92 17.0754204,17.92 17.0754204,22 L6.90751142,22 C6.90751142,18 7.30469536,18 7.30469536,14.72 C7.30469536,14.5820958 7.30416879,14.4377219 7.30311564,14.2868783 C7.16189664,12.9183228 6.95059177,11.7026967 6.66920105,10.64 L6.38887711,11.2610852 C6.32215943,11.4089048 6.15565267,11.4837271 6.0008221,11.4354635 L3.22551831,10.5703504 C3.06290094,10.5196596 2.96820511,10.350765 3.00978727,10.1855836 L4.33308578,4.92889834 C4.35386243,4.84636495 4.40656906,4.77546278 4.47961453,4.73178454 L6.72830973,3.38715539 C6.78448244,3.35361757 6.84801543,3.33879392 6.91053769,3.34222149 C6.90953086,3.3215047 6.9085221,3.3007642 6.90751142,3.28 L9.40273013,2 C10.2740705,2.5 11.1398271,2.75 12,2.75 C12.8601729,2.75 13.7202401,2.5 14.5802017,2 L16.8912572,3.18552777 C16.96049,3.22094519 17.0129393,3.27936302 17.0415261,3.3484881 C17.11879,3.33240258 17.2010587,3.34498492 17.2716903,3.38715539 L19.5203855,4.73178454 C19.5934309,4.77546278 19.6461376,4.84636495 19.6669142,4.92889834 L20.9902127,10.1855836 C21.0317949,10.350765 20.9370991,10.5196596 20.7744817,10.5703504 L17.9991779,11.4354635 C17.8443473,11.4837271 17.6778406,11.4089048 17.6111229,11.2610852 L17.330799,10.64 C17.0371467,11.7490035 16.8198213,13.0245545 16.6788228,14.4666528 Z" id="basic-jersey" :style="{'fill': getTeamColor(team.id)}"></path>
            </svg>
          </div>
          {{ getTeamShort(team.id, team.name) }}
        </div>
      </div>
      
      <div v-for="team in selectableTeams" class="input-checkbox" @click="teamChanged($event, team)">
        <input type="checkbox" :id="team.id" :name="team.id" :checked="selectedTeams[team.id]" />
        <label :for="team.id">
          <div class="team-logo">
            <img v-if="teamCode(team.id) && teamCode(team.id).length > 6" :src="teamLogo(team.id)" width="24">
            <div v-else-if="teamCode(team.id) && teamCode(team.id).length <= 6" class="flag">
              <flag :iso="teamCode(team.id)"/>
            </div>
            <svg v-else style="margin-right: 12px" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <path d="M16.6788228,14.4666528 C16.6784319,14.5535821 16.6782365,14.6380312 16.6782365,14.72 C16.6782365,17.92 17.0754204,17.92 17.0754204,22 L6.90751142,22 C6.90751142,18 7.30469536,18 7.30469536,14.72 C7.30469536,14.5820958 7.30416879,14.4377219 7.30311564,14.2868783 C7.16189664,12.9183228 6.95059177,11.7026967 6.66920105,10.64 L6.38887711,11.2610852 C6.32215943,11.4089048 6.15565267,11.4837271 6.0008221,11.4354635 L3.22551831,10.5703504 C3.06290094,10.5196596 2.96820511,10.350765 3.00978727,10.1855836 L4.33308578,4.92889834 C4.35386243,4.84636495 4.40656906,4.77546278 4.47961453,4.73178454 L6.72830973,3.38715539 C6.78448244,3.35361757 6.84801543,3.33879392 6.91053769,3.34222149 C6.90953086,3.3215047 6.9085221,3.3007642 6.90751142,3.28 L9.40273013,2 C10.2740705,2.5 11.1398271,2.75 12,2.75 C12.8601729,2.75 13.7202401,2.5 14.5802017,2 L16.8912572,3.18552777 C16.96049,3.22094519 17.0129393,3.27936302 17.0415261,3.3484881 C17.11879,3.33240258 17.2010587,3.34498492 17.2716903,3.38715539 L19.5203855,4.73178454 C19.5934309,4.77546278 19.6461376,4.84636495 19.6669142,4.92889834 L20.9902127,10.1855836 C21.0317949,10.350765 20.9370991,10.5196596 20.7744817,10.5703504 L17.9991779,11.4354635 C17.8443473,11.4837271 17.6778406,11.4089048 17.6111229,11.2610852 L17.330799,10.64 C17.0371467,11.7490035 16.8198213,13.0245545 16.6788228,14.4666528 Z" id="basic-jersey" :style="{'fill': getTeamColor(team.id)}"></path>
            </svg>
          </div>
          <!-- TODO @Lennard: Wenn in der Suche etwas eingegeben wird, sollen nur die Teams angezeigt werden, die genau den Text aus dem Suchfeld enthalten. Genau dieser Teil soll dann mittels <span>-Tag hervorgehoben werden. -->
          <span>{{ buildTeamName(team) }}</span>
        </label>
      </div>
      <div v-if="selectableTeams.length === 0 && teamsFilter.length > 0" class="input-infotext">
        <label>
          <div class="team-logo">
          </div>
          <span>{{ $t('errors.no_teams_match_filter', {filter: teamsFilter}) }}</span>
        </label>
      </div>
    </div>


    <modal class="modal scrollable" name="match-select" id="match-select" width="100%" height="100vh">
      <div class="modal-header">
        <div class="modal-header-left"></div>
        <div class="modal-header-center">{{ $t('select_matches.title') }}</div>
        <div class="modal-header-right">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('match-select')"
            aria-label="Close modal">
          </button>
        </div>
      </div>

      <div class="modal-body">

        <div class="matchday-selection">
          <div v-if="selectedMatchday > 1" class="matchday-selection__prev" v-on:click="decrement">
            <img src="../../assets/icons/ic_16_back.svg">
          </div>
          <div class="matchday-selection__title" v-on:click="opemMatchdaySelect">
            {{ matchdayTextGlobal(selectedMatchday, this.competition) }}
          </div>
          <div v-if="selectedMatchday < matchdayCount" class="matchday-selection__next" v-on:click="increment">
            <img src="../../assets/icons/ic_16_next.svg">
          </div>
        </div>

        <div v-if="this.competitionMatches.length > 0">
          <div v-for="matchday in [selectedMatchday]">
            <div v-for="match in matchDayMatches(matchday)" class="input-checkbox">
              <input :id="match.id" type="checkbox" v-model="selectedMatches[match.id]"/>
              <label :for="match.id">
                <span style="color: #ACACAC; margin-bottom: 5px;">{{ match.matchdate | formatDateTime }}</span>
                <span>{{ match.team_home }}</span>
                <span>{{ match.team_guest }}</span>
              </label>
            </div>
          </div>
        </div>

      </div>

      <div class="modal-footer">

        <div class="button__wrapper">
          <button v-on:click="closeMatchSelect">
            {{ $t('select_matches.save_selection') }}
          </button>
        </div>
      </div>
    </modal>

    <!-- Matchday Select Code -->
    <modal name="matchday-select" id="matchday-select" :scrollable="true" width="100%" height="auto">
      <div class="modal-header">
        <div class="modal-header-left"></div>
        <div class="modal-header-center">{{ $t('select_matchday') }}</div>
        <div class="modal-header-right">
          <button
              type="button"
              class="btn-close"
              @click="$modal.hide('matchday-select')"
              aria-label="Close modal">
          </button>
        </div>
      </div>
      <div class="matchday-select-list">
        <button v-for="md in matchdayCount" :key="md"
                v-bind:class="{ selected_matchday: md === selectedMatchday }"
                v-on:click="matchdaySelected(md)">{{ md }}</button>
      </div>
    </modal>
    
  </div>
</template>

<script>

import Vue from 'vue'
import APIService from '@/services/APIService'
import { SPORTS_TYPE_HANDBALL, TYPE_BETGAME_ID, TYPE_TEAMS_ID, TEAM_TYPE_WOMEN, SPORTS_TYPE_HOCKEY } from '@/main'

export default {
  name: 'SelectMatches',
  components: {},
  props: {
  },
  data () {
    return {
      competitionMatches: [],
      selectedMatches: {},
      selectedTeams: {},
      componentKey: 0,
      selectedMatchday: 1000,
      matchdayCount: 1,
      competition: 0,
      teamsFilter: '',
    }
  },
  beforeCreate () {
    Vue.$log.debug('SelectMatches.beforeCreate ' + this.betgameType + ' --- ' + this.selectedCompetition + ' --- ' + this.$store.state.createBetgame.competition)
  },
  created () {
    Vue.$log.debug('SelectMatches.created ' + this.betgameType + ' --- ' + this.selectedCompetition + ' --- ' + this.$store.state.createBetgame.competition)
    const t = this.$route.query.type
    if(t && parseInt(t) === TYPE_BETGAME_ID) {
      const c = this.$route.query.competition
      if(c && this.isValidCompetition(c) && this.isCompetitionAvailable(c)) {
        this.$store.dispatch('setCreateBetgame', {
          key: 'competition',
          value: c
        })
        .then(() => {
          Vue.$log.debug('SelectMatches.created ok competition ' + c)
        }).catch(function (error) {
          Vue.$log.debug('SelectMatches.created error ' + error)
        }) 
      }
    } else if(t && parseInt(t) === TYPE_TEAMS_ID) {
      const t = this.$route.query.team
      if(t && parseInt(t) > 0 && parseInt(t) < 1000) {
        Vue.set(this.selectedTeams, t, true)
        this.$store.dispatch('setSelectedTeams', this.selectedTeams ).then(() => {
        })
      }
    }
  },
  mounted () {
    Vue.$log.debug('SelectMatches.mounted ' + this.betgameType + ' --- ' + this.selectedCompetition + ' --- ' + this.$store.state.createBetgame.competition)
  },
  computed: {
    selectedTeamsList() {
      Vue.$log.debug('selectedTeamsList ' + JSON.stringify(this.selectedTeams))
      const selectedIDs = Object.keys(this.selectedTeams).filter(q => this.selectedTeams[q] === true)
      const teams = []
      for(let i = 0; i < selectedIDs.length; i++) {
        const teamID = parseInt(selectedIDs[i])
        const team = this.$store.state.teams.filter(q => q.id === teamID)
        if(team.length > 0) {
          teams.push(team[0])
        }
      }
      return teams.sort((a, b) => a.id - b.id)
    },
    selectableTeams() {
      const temp = this.$store.getters.getCurrentTeams
      if(this.teamsFilter.length > 0) {
        return temp.filter(t => t.name.toLowerCase().includes(this.teamsFilter.toLowerCase())).sort((a, b) => a.id - b.id)
      }
      Vue.$log.debug('selectableTeams ' + JSON.stringify(temp.sort((a, b) => a.id - b.id)))
      return temp.sort((a, b) => a.id - b.id)
    },
    betgameType() {
      return this.$store.state.createBetgame.betgameType
    },
    selectedCompetition() {
      return '' + this.$store.state.createBetgame.competition
    }
  },
  methods: {
    buildTeamName(team) {
      var n = team.name
      if(team.team_type === TEAM_TYPE_WOMEN) {
        n += ' - ' + this.$i18n.t('women')
      }
      if(team.sports_type === SPORTS_TYPE_HANDBALL) {
        n += ' - ' + this.$i18n.t('handball')
      } else if(team.sports_type === SPORTS_TYPE_HOCKEY) {
        n += ' - ' + this.$i18n.t('field_hockey')
      }
      return n
    },
    teamChanged(event, team) {
      Vue.$log.debug('teamChanged ' + event.target.checked + ' - ' + JSON.stringify(this.selectedTeams))
      if(this.selectedTeams[team.id]) {
        const t = this.selectedTeams[team.id]
        Vue.set(this.selectedTeams, team.id, !t)
      } else {
        Vue.set(this.selectedTeams, team.id, true) 
      }
      this.$store.dispatch('setSelectedTeams', this.selectedTeams).then(() => {
        this.componentKey += 1
      })
    },
    unselectTeam(teamId) {
      Vue.$log.debug('unselectTeam ' + teamId)
      Vue.set(this.selectedTeams, teamId, false)
      this.$store.dispatch('setSelectedTeams', this.selectedTeams ).then(() => {
        this.componentKey += 1
      })
    },
    teamCode(teamId) {
      const l = this.getTeamLogo(teamId)
      if(l) {
        return l
      }
      return null
    },
    teamLogo(teamId) {
      const l = this.getTeamLogo(teamId)
      if(l) {
        return this.getBGValue('spmau') + '/images/jerseys/24/' + l
      }
      return null
    },
    matchdaySelected (m) {
      Vue.$log.debug('selectmatchday matchdaySelected ' + m)
      this.selectedMatchday = m
      this.$modal.hide('matchday-select');
      document.getElementById("app").scrollIntoView()
    },
    opemMatchdaySelect() {
      document.getElementById("app").scrollIntoView()
      this.$modal.show('matchday-select');
    },
    increment () {
      if (this.selectedMatchday < this.matchdayCount) {
        this.selectedMatchday++
      } else {
        this.selectedMatchday = 1
      }
      if(this.matchDayMatches(this.selectedMatchday).length === 0) {
        if (this.selectedMatchday < this.matchdayCount) {
          this.increment()
        }
      } 
    },
    decrement () {
      if (this.selectedMatchday > 1) {
        this.selectedMatchday--
      } else {
        this.selectedMatchday = this.matchdayCount
      }
      if(this.matchDayMatches(this.selectedMatchday).length === 0) {
        if (this.selectedMatchday > 1) {
          this.decrement()
        }
      }
    },
    selectedMatchesCount(competition) {
      if(this.$store.state.createBetgame && this.$store.state.createBetgame.selectedMatches) {
        const t = this.$store.state.createBetgame.selectedMatches['' + competition]
        // Vue.$log.debug('selectedMatchesCount ' + competition + ' --- ' + JSON.stringify(t))
        return t ? Object.values(t).filter(q => q === true).length : 0
      }
      return 0
    },
    closeMatchSelect() {
      this.$store.dispatch('setSelectedMatches', { competition: this.competition, matches: this.selectedMatches }).then(() => {
        this.componentKey += 1
        this.$modal.hide('match-select')
      })
    },
    matchDayMatches (matchday) {
      return this.competitionMatches.filter(q => parseInt(q.matchday) === parseInt(matchday))
    },
    opemMatchSelect(competition) {
      this.competition = competition
      this.selectedMatchday = 1000
      this.matchdayCount = 1
      APIService.getSchedule(competition, this.$store.state.currentSeason.id)
      .then(response => {
        Vue.$log.debug('SelectMatches getSchedule ok ' + response.data.length)
        const temp = response.data
        temp.sort(function (a, b) {
          if (a.matchday === b.matchday) {
            return a.matchdate.localeCompare(b.matchdate)
          } else {
            return a.matchday - b.matchday
          }
        })
        for(var i = 0; i < temp.length; i++) {
          const m = temp[i]
          const md = m.matchday
          if(md > this.matchdayCount) {
            this.matchdayCount = md
          }
          if(!Number.isInteger(m.goalshome) && !Number.isInteger(m.goalsguest) &&
              !Number.isInteger(m.livegoalshome) && !Number.isInteger(m.livegoalsguest) && m.status !== 'annulliert') {
            if(md < this.selectedMatchday) {
              this.selectedMatchday = md
            }
          }
        }
        this.competitionMatches = temp.filter(m => !Number.isInteger(m.goalshome) && !Number.isInteger(m.goalsguest) &&
            !Number.isInteger(m.livegoalshome) && !Number.isInteger(m.livegoalsguest) && m.status !== 'annulliert')
        Vue.$log.debug('SelectMatches.getSchedule result ' + this.matchdayCount + ' --- ' + this.selectedMatchday + ' --- ' + this.competitionMatches.length)

        const c = this.$store.state.createBetgame.selectedMatches['' + competition]
        if(c) {
          this.selectedMatches = JSON.parse(JSON.stringify(c))
        } else {
          this.selectedMatches = {}
        }
        
        document.getElementById("app").scrollIntoView()
        this.$modal.show('match-select');

      })
      .catch(error => {
        Vue.$log.debug('SelectMatches.created getSchedule ' + error)
      })
    },
    matchSelected (m) {
      Vue.$log.debug('matchSelected ' + m)
      this.$modal.hide('match-select');
      document.getElementById("app").scrollIntoView()
      this.componentKey += 1
    },
    competitionSelected(data) {
      Vue.$log.debug('competitionSelected ' + data.target.value)
      if(data.target.value && data.target.value > 0) {
        this.$store.dispatch('setCreateBetgame', {
          key: 'competition',
          value: data.target.value
        })
        .then(() => {
          Vue.$log.debug('SelectMatches.competitionSelected ok ' + data.target.value)
        }).catch(function (error) {
          Vue.$log.debug('SelectMatches.competitionSelected error ' + error)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.selectmatches {
  max-width: 400px;
  margin: 16px auto 16px;
}



.v--modal-box {
  top: 0 !important;
  height: calc(100vh - 32px) !important;
}

.modal {

  .matchday-selection {
    display: flex;
    margin-bottom: 16px;
    padding: 16px 0;
    margin-top: -16px;
    position: sticky;
    z-index: 2;
    top: -16px;
    background: $colorWhite;
    
    &__prev, &__next {
      display: flex;
      align-items: center;
      margin: -8px;
      padding: 8px;
    }

    &__title {
      flex: 1;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: $colorGreyDark;
    }
  }

  .input-checkbox {
    label {
      display: flex;
      flex-direction: column;
      padding: 13px 24px 13px 0;
      font-size: 14px;
    }
  }

  .modal-body {
    max-height: calc(100vh - 144px);
    overflow-y: auto;
  }

  .modal-footer {
    position: sticky;
    bottom: 0;
  }

}

.matchday-select-list {
  padding: 64px 8px 16px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 576px;
  margin: 0px auto;

  button {
    background: rgba($colorGreyDark, .04);
    width: calc(20% - 16px);
    box-shadow: inset 0 0 0 1px rgba(95,93,97,0.08);
    border-radius: 4px;
    color: $colorGreyDark;
    padding: 0;
    display: flex;
    flex: 1 0 calc(20% - 16px);
    flex-grow: 0;
    margin: 8px 8px;
    justify-content: center;
    align-items: center;
    position: relative;

    @media screen and (min-width: 768px) {
      width: calc(16.3% - 16px);
      flex: 1 0 calc(16.3% - 16px);
      flex-grow: 0;
    }

    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }
}

.menu_header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  height: 64px;
  align-items: center;
  background: linear-gradient(to bottom, $colorWhite 65%, rgba($colorWhite, .8) 80%, rgba($colorWhite, 0) 100%);

  &-title {
    font-size: 17px;
    color: $colorGreyDark;
    text-align: center;
    justify-content: center;
    display: flex;
    flex: 1;
  }
}

.selected-teams {
  display: flex;
  padding: 16px 0 8px;

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 10px;
    font-weight: 500;
    color: $colorGreyDark;

    &:hover {
      img {
        background: rgba($colorGreyDark, .12);
      }
    }

    &:not(:last-child) {
      margin-right: 16px;
    }

    img, svg, .flag {
      width: 48px;
      height: 48px;
      padding: 12px;
      background: rgba($colorGreyDark, .08);
      border-radius: 50%;
      margin-bottom: 4px;
      transition: all .2s ease-in-out;

      span {
        width: 20px;
        height: 20px;
        margin: 2px;
        border-radius: 50%;
      }
    }

    &__close {
      content: '';
      position: absolute;
      padding: 8px;
      top: -8px;
      right: -8px;
      cursor: pointer;
      transition: all .2s ease-in-out;

      &:hover {

        transform: scale(1.1);
        transform-origin: center center;
      }

      &__icon {
        position: relative;
        width: 16px;
        height: 16px;
        background: $colorGreyDark;
        border-radius: 50%;

        &:before {
          content: '';
          position: absolute;
          width: 8px;
          height: 1.5px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          background: $colorWhite;
        }

        &:after {
          content: '';
          position: absolute;
          width: 8px;
          height: 1.5px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
          background: $colorWhite;
        }
      }
    }
  }
}

.input-checkbox {
  label {
    display: flex;
    align-items: center;

    span {
      font-weight: 500;
    }

    img, svg, .flag {
      width: 24px;
      margin-right: 12px;

      span {
        width: 20px;
        height: 20px;
        margin: 2px;
        border-radius: 50%;
      }
    }
  }
}

small {
  justify-content: flex-start;
  margin-top: 24px;
  margin-bottom: -4px;
}

</style>
