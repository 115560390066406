<template>
  <div>
    <vue-final-modal v-model="showModal" @click-outside="clickOutside" class="chat__context">

      <div class="chat__context__content">

        <!-- Current Message -->
        <div class="chat__context__message">
          <div v-if="chatItem.sender"class="chat__context__message__title">
            Ich ({{ chatItem.username }})
            <span>{{ chatItem.time | formatTimeChat }}</span>
          </div>
          <div v-else class="chat__context__message__title">
            {{ chatItem.username }}
            <span>{{ chatItem.time | formatTimeChat }}</span>
          </div>
          <p>{{ chatItem.text }}</p>
        </div>

        <!-- Actions -->
        <div class="chat__context__list">

          <!-- Emoji Reaction -->
          <div class="chat__context__list__emojis" :class="containerClass">
            <div v-for="emoji in emojis" v-on:click="emojiClicked(emoji)" class="chat__context__list__emojis__item"
                 v-bind:class="{ 'chat__context__list__emojis__item--active': emojiActive(emoji) }">
              {{ emoji }}
            </div>
            <div v-on:click="expandEmojisClicked" class="chat__context__list__emojis__item chat__context__list__emojis__item--add" :class="iconClass">
              <img src="../../assets/chat/ic_16_plus.svg">
            </div>
            <div v-for="emoji in moreEmojis" v-on:click="emojiClicked(emoji)" class="chat__context__list__emojis__item" :class="moreEmojisClass">
              {{ emoji }}
            </div>
          </div>

          <!-- Common Actions -->
          <div v-on:click="replyClicked()" class="chat__context__list__item">
            <div class="chat__context__list__item__label">
              {{ $t('feed.reply') }}
            </div>
            <img src="@/assets/chat/ic_20_answer.svg" alt="">
          </div>
          <div v-on:click="copyClicked()" class="chat__context__list__item">
            <div class="chat__context__list__item__label">
              {{ $t('feed.copy') }}
            </div>
            <img src="@/assets/chat/ic_20_copy.svg" alt="">
          </div>
          <div v-if="deleteAvailable" v-on:click="deleteClicked()" class="chat__context__list__item chat__context__list__item--delete">
            <div class="chat__context__list__item__label">
              {{ $t('feed.delete') }}
            </div>
            <img src="@/assets/chat/ic_20_delete.svg" alt="">
          </div>

        </div>

      </div>

    </vue-final-modal>
  </div>
</template>

<script>

export default {
  name: 'ChatContextMenu',
  props: {
    chatItem: Object,
    showModal: false
  },
  data () {
    return {
      showAllEmojis: false,
      emojis: [
        '👍', '❤️', '😂', '🚀', '😢️', '🙏', '⚽', '👋'
      ],
      moreEmojis: [
          '😀','😃','😄','😁','😆','🥹','😊','🥲','🤣','😂','😅','😇','🙂','🙃','😉','😌','😍','😋','😚','😙',
        '😗','😘','🥰','😛','😝','😜','🤪','🤨','🧐','🐶','🐱','🐭','🐹','🐰','🐰','🦊','⚽','🏀','🏈','⚾','🥎',
        '🎾','🏐','🏉','🥏','🎱','🪀','🏓'
      ]
    }
  },
  mounted () {
  },
  methods: {
    emojiActive(emoji) {
      const reactions = this.chatItem.reactions ? this.chatItem.reactions : []
      for (let i = 0; i < reactions.length; i++) {
        if(reactions[i].text === emoji && parseInt(reactions[i].user_id) === this.$store.state.user.id) {
          return true        
        }
      }
      return false
    },
    copyClicked() {
      this.showAllEmojis = false
      this.$emit('copyClicked', { selected: null } )
    },
    replyClicked() {
      this.showAllEmojis = false
      this.$emit('replyClicked', { selected: null } )
    },
    deleteClicked() {
      this.showAllEmojis = false
      this.$emit('deleteClicked', { selected: null } )
    },
    clickOutside() {
      this.showAllEmojis = false
      this.$emit('emojiClicked', { selected: null } )
    },
    emojiClicked(emoji) {
      this.showAllEmojis = false
      this.$emit('emojiClicked', { selected: emoji } )
    },
    expandEmojisClicked() {
      this.showAllEmojis = !this.showAllEmojis
    }
  },
  computed: {
    deleteAvailable() {
      return this.chatItem.sender
    },
    containerClass() {
      return this.showAllEmojis ? 'chat__context__list__emojis--expanded' : ''
    },
    iconClass() {
      return !this.showAllEmojis ? '' : 'chat__context__list__emojis__item--invisible'
    },
    moreEmojisClass() {
      return !this.showAllEmojis ? 'chat__context__list__emojis__item--invisible' : ''
    }
  }
}
</script>

<style lang="scss">
.chat__context {
  display: flex;

  .vfm--inset {
    display: flex;
  }

  .vfm__content {
    display: flex;
    margin: auto auto 0 auto;
    width: 100%;
    max-width: 576px;

    @media screen and (min-width: 768px) {
      margin: auto;
    }
  }

  .vfm--overlay {
    backdrop-filter: blur(4px);
  }

  &__content {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    
    .vfm-enter-active & {
      animation: .2s enterAnimation !important;
      animation-fill-mode: forwards !important;
    }

    @keyframes enterAnimation {
      from {  
        transform: translateY(48px)
      }
      to {
        transform: translateY(0)
      }
    }

    .vfm-leave-active & {
      animation: .2s leaveAnimation ease-in-out !important;
      animation-fill-mode: forwards !important;
    }

    @keyframes leaveAnimation {
      from {  
        transform: translateY(0)
      }
      to {
        transform: translateY(48px)
      }
    }
  }

  &__message {
    background: $colorWhite;
    padding: 21px 24px 18px;
    border-radius: 4px 24px 24px 24px;
    width: 100%;
    box-sizing: border-box;

    &__title {
      display: flex;
      font-size: 14px;
      font-weight: 500;
      color: $colorGreyDark;
      margin-bottom: 8px;

      .chat__item--sender & {
        color: $colorWhite;
      }

      span {
        margin-top: 3px;
        font-size: 11px;
        font-weight: 400;
        opacity: .48;
        margin-left: auto;
      }
    }

    p {
      font-size: 16px;
      line-height: 22px;
      word-break: break-word;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    background: $colorWhite;
    border-radius: 8px;
    margin-top: 12px;
    overflow-y: scroll;

    &__emojis {
      display: grid;
      grid-template-columns: repeat(9, 1fr);
      grid-template-rows: repeat(1, 1fr);
      grid-row-gap: 8px;
      padding: 5px;
      margin: 4px 0;
      max-height: 160px;
      overflow-y: scroll;
      overflow-x: hidden;

      &--expanded {
        position: relative;
        box-shadow: inset 0 -1px 0 0 rgba($colorGreyDark, .08);

        &:after {
          position: sticky;
          content: '';
          bottom: -5px;
          margin: -5px;
          grid-column: span 9;
          height: 32px;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.64) 100%);
        }
      }

      &__item {
        font-size: 22px;
        line-height: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        max-width: 40px;
        height: 40px;
        border-radius: 50%;
        transition: all .2s ease-in-out;

        &--invisible {
          display: none;
        }

        &--active, &:hover {
          background: rgba(#000000, .08);
        }

        &--add {
          width: 24px;
          height: 24px;
          margin: 8px;
          background: rgba(#000000, .04);

          img {
            opacity: .64;
          }

          &:hover {
            img {
              opacity: .8;
            }
          }
        }
      }
    }

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: $colorGreyDark;
      padding: 12px 16px;
      transition: all .2s ease-in-out;

      &:hover {
        background: rgba(#000000, .04);
      }

      &:not(:last-child) {
        box-shadow: inset 0 -1px 0 0 rgba($colorGreyDark, .08);
      }

      &--delete {
        color: $colorNegative;
      }

      &__label {
        flex: 1;
      }

      &__avatar {
        position: relative;
        flex-shrink: 0;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin: -2px 8px -2px 0;
        overflow: hidden;
        background: rgba($colorGreyDark, .08);

        &:before {
          position: absolute;
          z-index: 1;
          top: 8px;
          left: 8px;
          width: 16px;
          height: 16px;
          content: url('../../assets/chat/ic_16_user.svg');
          opacity: .48;
        }

        img {
          position: relative;
          z-index: 2;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      span {
        font-size: 22px;
      }

      > img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
