<template>
    <div class="profileChangeNotifications" id="profileChangeNotifications">
        
        <NavigationBar>
            <template v-slot:left>
                <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
            </template>
            <template v-slot:center>
              {{ $t('profile.notification_settings') }}
            </template>
            <template v-slot:right>
            </template>
        </NavigationBar>
        
        <form @submit.prevent="changeNotifications">
            <div class="input-container">
              <div>
                  <div class="input-header">
                    {{ $t('profile.enable_bet_reminder_email_notifications') }}
                  </div>
                  <div class="input-infotext">
                    {{ $t('profile.enable_email_notifications_text') }}
                  </div>
                  <div class="input-checkbox">
                      <input id="email_news" type="checkbox" v-model="news_email_notifications_new"/>
                      <label for="email_news">{{ $t('profile.email_news') }}</label>
                  </div>
                  <div class="input-checkbox">
                    <input id="email_reminder" type="checkbox" v-model="email_notifications_new"/>
                    <label for="email_reminder">{{ $t('profile.email_reminder') }}</label>
                  </div>
              </div>
              <div v-if="!this.isApp()" class="input-button-row">
                <button name="button" type="submit">
                  {{ $t('button_save_text') }}
                </button>
              </div>
            </div>
            <div v-if="this.isApp()" class="input-container" style="margin-top: 16px;">
                <div>
                    <div class="input-header">
                      {{ $t('profile.enable_bet_reminder_push_notifications') }}
                    </div>
                    <div class="input-infotext">
                      {{ $t('profile.enable_push_notifications_text') }}
                    </div>
                    <div class="input-checkbox">
                      <input id="push_news" type="checkbox" v-model="news_push_notifications_new"/>
                      <label for="push_news">{{ $t('profile.push_news') }}</label>
                    </div>
                    <div class="input-checkbox">
                      <input id="push_reminder" type="checkbox" v-model="push_notifications_new"/>
                      <label for="push_reminder">{{ $t('profile.push_reminder') }}</label>
                    </div>
                    <div v-if="this.isSPMUser()" class="input-checkbox">
                      <input id="push_chat" type="checkbox" v-model="chat_push_notifications_new"/>
                      <label for="push_chat">{{ $t('profile.push_chat') }}</label>
                    </div>
                </div>
            </div>

            <div v-if="this.isApp()" class="input-button-row" style="margin-top: 16px;">
              <button name="button" type="submit">
                {{ $t('button_save_text') }}
              </button>
            </div>
        </form>
    
        <ul class="error-container">
            <li v-for="error in errors">{{ error }}</li>
        </ul>
    
    </div>
</template>

<script>
  
  import NavigationBar from './../components/NavigationBar'
  import { TOAST_DURATION } from '../main'
  import Vue from 'vue'
  
  export default {
    name: 'profileChangeNotifications',
    components: { NavigationBar },
    data () {
      return {
        email_notifications_new: '',
        push_notifications_new: '',
        news_email_notifications_new: '',
        news_push_notifications_new: '',
        chat_push_notifications_new: '',
        errors: []
      }
    },
    created () {
      this.email_notifications_new = this.$store.state.user.email_notifications !== undefined ? this.$store.state.user.email_notifications : true
      this.push_notifications_new = this.$store.state.user.push_notifications !== undefined ? this.$store.state.user.push_notifications : true
      this.news_email_notifications_new = this.$store.state.user.news_email_notifications !== undefined ? this.$store.state.user.news_email_notifications : true
      this.news_push_notifications_new = this.$store.state.user.news_push_notifications !== undefined ? this.$store.state.user.news_push_notifications : true
      this.chat_push_notifications_new = this.$store.state.user.chat_push_notifications !== undefined ? this.$store.state.user.chat_push_notifications : true
      Vue.$log.debug('profileChangeNotifications created ' + this.email_notifications_new + ' --- ' + this.push_notifications_new + ' --- ' +
          this.news_email_notifications_new + ' --- ' + this.news_push_notifications_new + ' --- ' + this.chat_push_notifications_new + ' --- ' + 
          JSON.stringify(this.$store.state.unsubscribed))
      if(this.$store.state.unsubscribed && this.$store.state.unsubscribed.fk_user > 0 && this.news_email_notifications_new === true) {
        this.news_email_notifications_new = false
        this.$store.dispatch('updateUserNotifications', {
          id: '' + this.$store.state.user.id,
          email: this.$store.state.user.email,
          email_notifications: this.email_notifications_new,
          push_notifications: this.push_notifications_new,
          news_email_notifications: false,
          news_push_notifications: this.news_push_notifications_new, 
          chat_push_notifications: this.chat_push_notifications_new
        })
        .then(() => {
        }).catch(function (error) {
          alert(this.$i18n.t('profile.error_settings_not_saved') + ' ' + error)
        })
      }
    },
    mounted () {
    },
    computed: {
    },
    methods: {
      goBack () {
        this.$router.push('/profile')
      },
      changeNotifications () {
        this.errors = []
        Vue.$log.debug('profileChangeNotifications changeNotifications ' + this.email_notifications_new + ' --- ' + this.push_notifications_new + ' --- ' + 
            this.news_email_notifications_new + ' --- ' + this.news_push_notifications_new + ' --- ' + this.chat_push_notifications_new)
        if (this.errors.length === 0) {
          this.$store.dispatch('updateUserNotifications', {
            id: '' + this.$store.state.user.id,
            email: this.$store.state.user.email,
            email_notifications: this.email_notifications_new,
            push_notifications: this.push_notifications_new,
            news_email_notifications: this.news_email_notifications_new,
            news_push_notifications: this.news_push_notifications_new, 
            chat_push_notifications: this.chat_push_notifications_new
          })
          .then(() => {
            this.$toast.open({ message:  this.$i18n.t('profile.settings_saved'),
                position: 'top',
                duration: TOAST_DURATION,
                type: 'success'
            })
          }).catch(function (error) {
            this.email_notifications_new = this.$store.state.user.email_notifications
            this.push_notifications_new = this.$store.state.user.push_notifications
            this.news_email_notifications_new = this.$store.state.user.news_email_notifications
            this.news_push_notifications_new = this.$store.state.user.news_push_notifications
            this.chat_push_notifications_new = this.$store.state.user.chat_push_notifications
            if(this.$store.state.unsubscribed && this.$store.state.unsubscribed.fk_user > 0) {
              this.news_email_notifications_new = false
            }
            alert(this.$i18n.t('profile.error_settings_not_saved') + ' ' + error)
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
    .profileChangeNotifications {
        
        form {
            padding-top: 16px;
            max-width: 432px;
            margin: 0px auto;
        }
        
        h1 {
            text-align: center;
        }
        
        p {
            text-align: center;
            margin-bottom: 26px;
        }

      .input-checkbox {
        label {
          border-bottom: none;
        }
      }
    }
</style>
