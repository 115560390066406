/* eslint-disable */
<template>
  <div class="tournament" v-bind:style="{ paddingBottom: $store.state.paddingBottom + 'px' }">
  
    <NavigationBar >
      <template v-slot:center>
        {{ $t('tournament.groups_and_schedule') }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>

    <div id="tournament_nav">
      <div :id="tab.name" v-for="(tab, index) in tabsnew" :key="tab.name" class="tournament_tab" v-bind:class="{ tournament_tab_selected: activeTab === tab.name, last_tab: index === (tabs.length - 1) }" v-on:click="handleClick(tab.name)">{{ tab.name }}</div>
    </div>
    
    <swiper ref="mySwiper" :options="swiperOptions" @slideChangeTransitionEnd="slideChangeTransitionEnd">
      <swiper-slide v-for="(tab, index) in tabsnew" :key="'content_' + tab.name">
        <tournament-group v-if="tab.type === 'TournamentGroup'" class="tournament-slider-item" :dataObject="tab">
        </tournament-group>
        <tournament-round v-else-if="tab.type === 'TournamentRound'" class="tournament-slider-item" :dataObject="tab">
        </tournament-round>
      </swiper-slide>
    </swiper>
    
    <!--
    <div id="tournament_nav">
      <div :id="tab.name" v-for="(tab, index) in tabsnew" :key="tab.name" class="tournament_tab" v-bind:class="{ tournament_tab_selected: activeTab === tab.name, last_tab: index === (tabs.length - 1) }" v-on:click="handleClick(tab.name)">{{ tab.name }}</div>
    </div>
    <div class="tournament_content">
      <transition-group class="tournament-slider" name="slide" tag="div">
        <component class="tournament-slider-item" v-for="(tab, index) in tabsnew" :key="'content_' + tab.name" :dataObject="tab" v-bind:is="tab.type" v-show="activeTab === tab.name">
        </component>
      </transition-group>
    </div>
    -->
    
  </div>
</template>

<script>
import NavigationBar from './../components/NavigationBar'
import TournamentGroup from '../components/TournamentGroup'
import TournamentRound from '../components/TournamentRound'

export default {
  name: 'tournament',
  components: { NavigationBar, TournamentGroup, TournamentRound },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
      },
      activeTab: this.$store.state.language === 'de' ? 'Gruppe A' : 'Group A',
      tabs: [
        {
          name: 'Gruppe A',
          type: 'TournamentGroup',
          matches: [
            {
              codeHome: 'se',
              codeGuest: 'gb-wls',
              teamHome: 'Schweden',
              teamGuest: 'Wales',
              goalsHome: '1',
              goalsGuest: '2',
              date: 'Montag, 02.07.2020 15:00 Uhr'
            },
            {
              codeHome: 'it',
              codeGuest: 'es',
              teamHome: 'Italien',
              teamGuest: 'Spanien',
              goalsHome: '0',
              goalsGuest: '2',
              date: 'Montag, 07.07.2020 22:30 Uhr'
            }
          ],
          table: [
            {
              position: '1',
              code: 'pl',
              name: 'Polen',
              mathes: '2',
              points: '4',
              goals: '6',
              goalsAgainst: '3',
            },
            {
              position: '2',
              code: 'se',
              name: 'Schweden',
              mathes: '2',
              points: '3',
              goals: '3',
              goalsAgainst: '3',
            },
            {
              position: '3',
              code: 'ch',
              name: 'Schweiz',
              mathes: '2',
              points: '2',
              goals: '3',
              goalsAgainst: '3',
            },
            {
              position: '4',
              code: 'gb-wls',
              name: 'Wales',
              mathes: '2',
              points: '1',
              goals: '1',
              goalsAgainst: '3',
            }
          ]
        },
        {
          name: 'Gruppe B',
          type: 'TournamentGroup'
        },
        {
          name: 'Gruppe C',
          type: 'TournamentGroup'
        },
        {
          name: 'Achtelfinale',
          type: 'TournamentRound',
          matches: [
            {
              codeHome: 'gb-eng',
              codeGuest: 'pt',
              teamHome: 'England',
              teamGuest: 'Portugal',
              goalsHome: '1',
              goalsGuest: '2',
              date: 'Montag, 22.07.2020 15:00 Uhr'
            },
            {
              codeHome: 'de',
              codeGuest: 'es',
              teamHome: 'Deutschland',
              teamGuest: 'Spanien',
              goalsHome: '0',
              goalsGuest: '2',
              date: 'Montag, 07.07.2020 22:30 Uhr'
            }
          ],
        },
        {
          name: 'Viertelfinale',
          type: 'TournamentRound'
        },
        {
          name: 'Halbfinale',
          type: 'TournamentRound'
        },
        {
          name: 'Finale',
          type: 'TournamentRound'
        }
      ]
    }
  },
  created() {
    if (this.$store.state.teams.length === 0) {
      this.$store.dispatch('fetchTeams', [])
    }
    if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.season) {
      this.$store.dispatch('fetchTournamentRounds', { fk_competition: this.$store.state.betgame.competition.id,
        fk_season: this.$store.state.betgame.season.id }).then(() => {
      })
    }
  },
  mounted() {
    if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 10700) {
      if(this.$store.state.currentMatchdayBet) {
        this.swiper.slideTo(this.$store.state.currentMatchdayBet + 2)
      }
    }
    if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 4228) {
      this.activeTab = this.$store.state.language === 'de' ? 'Gruppe 1' : 'Group 1'
    }
  },
  computed: {
    swiper() {
      return document.querySelector('.swiper-container').swiper
    },
    tabsnew() {
      var result = []
      const tr = this.$store.state.tournamentRounds
      for(var i=0; i<tr.length; i++) {
        const round = tr[i]
        if(round.type === 1) {
          const tg = this.$store.state.tournamentGroups.filter(s => s.fk_tournament_round === round.id).sort(function(a, b) {
            return a.sorting - b.sorting
          })
          for(var j=0; j<tg.length; j++) {
            const group = tg[j]
            const tournametmatches = this.$store.state.tournamentMatches.filter(s => s.fk_tournament_group === group.id)
            const roundMatches = this.getRoundMatches(tournametmatches)
            const tt = this.$store.state.tournamentGroupTables.filter(s => s.fk_tournament_group === group.id)
            const table = this.getGroupTable(tt)
            result.push({
              name: this.$store.state.language === 'de' ? group.name : group.name_int,
              type: 'TournamentGroup',
              matches: roundMatches,
              table: table
            })
          }
        } else {
          if(this.$store.state.betgame && this.$store.state.betgame.competition &&
              this.$store.state.betgame.competition.id === 533 && round.id > 27) {
            const roundMatches = this.getRoundMatchesStatic(this.$store.state.betgame.competition.id, round.id)
            result.push({
              name: this.$store.state.language === 'de' ? round.name : round.name_int,
              type: 'TournamentRound',
              matches: roundMatches
            })
          } else if(this.$store.state.betgame && this.$store.state.betgame.competition &&
              this.$store.state.betgame.competition.id === 139 && round.id > 100) {
            const roundMatches = this.getRoundMatchesStaticWM2022(this.$store.state.betgame.competition.id, round.id)
            result.push({
              name: this.$store.state.language === 'de' ? round.name : round.name_int,
              type: 'TournamentRound',
              matches: roundMatches
            })
          } else {
            const tournametmatches = this.$store.state.tournamentMatches.filter(s => s.fk_tournament_round === round.id)
            const roundMatches = this.getRoundMatches(tournametmatches)
            result.push({
              name: this.$store.state.language === 'de' ? round.name : round.name_int,
              type: 'TournamentRound',
              matches: roundMatches
            })
          }
        }
      }
      return result
    }
  },
  methods: {
    getRoundMatchesStaticWM2022 (tournament, round) {
      var roundMatches = []
      if(round === 25) {
        roundMatches.push({
          codeHome: 'nl',
          codeGuest: 'us',
          colorHome: '',
          colorGuest: '',
          teamHome: this.$store.state.language === 'de' ? 'Niederlande' : 'Netherlands',
          teamGuest: this.$store.state.language === 'de' ? 'USA' : 'USA',
          goalsHome: '3',
          goalsGuest: '1',
          date: '2022-12-03T16:00:00'
        })
        roundMatches.push({
          codeHome: 'ar',
          codeGuest: 'au',
          colorHome: '',
          colorGuest: '',
          teamHome: this.$store.state.language === 'de' ? 'Argentinien' : 'Argentina',
          teamGuest: this.$store.state.language === 'de' ? 'Australien' : 'Australia',
          goalsHome: '2',
          goalsGuest: '1',
          date: '2022-12-03T20:00:00'
        })
        roundMatches.push({
          codeHome: 'fr',
          codeGuest: 'pl',
          colorHome: '',
          colorGuest: '',
          teamHome: this.$store.state.language === 'de' ? 'Frankreich' : 'France',
          teamGuest: this.$store.state.language === 'de' ? 'Polen' : 'Poland',
          goalsHome: '',
          goalsGuest: '',
          date: '2022-12-04T16:00:00'
        })
        roundMatches.push({
          codeHome: 'gb-eng',
          codeGuest: 'sn',
          colorHome: '',
          colorGuest: '',
          teamHome: this.$store.state.language === 'de' ? 'England' : 'England',
          teamGuest: this.$store.state.language === 'de' ? 'Sénégal' : 'Senegal',
          goalsHome: '',
          goalsGuest: '',
          date: '2022-12-04T20:00:00'
        })
        roundMatches.push({
          codeHome: 'jp',
          codeGuest: 'hr',
          colorHome: '',
          colorGuest: '',
          teamHome: this.$store.state.language === 'de' ? 'Japan' : 'Japan',
          teamGuest: this.$store.state.language === 'de' ? 'Kroatien' : 'Croatia',
          goalsHome: '',
          goalsGuest: '',
          date: '2022-12-05T16:00:00'
        })
        roundMatches.push({
          codeHome: 'br',
          codeGuest: 'kr',
          colorHome: '',
          colorGuest: '',
          teamHome: this.$store.state.language === 'de' ? 'Brasilien' : 'Brazil',
          teamGuest: this.$store.state.language === 'de' ? 'Südkorea' : 'South Korea',
          goalsHome: '',
          goalsGuest: '',
          date: '2022-12-05T20:00:00'
        })
        roundMatches.push({
          codeHome: 'ma',
          codeGuest: 'es',
          colorHome: '',
          colorGuest: '',
          teamHome: this.$store.state.language === 'de' ? 'Marokko' : 'Morocco',
          teamGuest: this.$store.state.language === 'de' ? 'Spanien' : 'Spain',
          goalsHome: '',
          goalsGuest: '',
          date: '2022-12-06T16:00:00'
        })
        roundMatches.push({
          codeHome: 'pt',
          codeGuest: 'ch',
          colorHome: '',
          colorGuest: '',
          teamHome: this.$store.state.language === 'de' ? 'Portugal' : 'Portugal',
          teamGuest: this.$store.state.language === 'de' ? 'Schweiz' : 'Switzerland',
          goalsHome: '',
          goalsGuest: '',
          date: '2022-12-06T20:00:00'
        })
      } else if(round === 26) {
        roundMatches.push({
          codeHome: 'hr',
          codeGuest: 'br',
          colorHome: '',
          colorGuest: '',
          teamHome: this.$store.state.language === 'de' ? 'Kroatien' : 'Croatia',
          teamGuest: this.$store.state.language === 'de' ? 'Brasilien' : 'Brazil',
          goalsHome: '',
          goalsGuest: '',
          date: '2022-12-09T16:00:00'
        })
        roundMatches.push({
          codeHome: 'nl',
          codeGuest: 'ar',
          colorHome: '',
          colorGuest: '',
          teamHome: this.$store.state.language === 'de' ? 'Niederlande' : 'Netherlands',
          teamGuest: this.$store.state.language === 'de' ? 'Argentinien' : 'Argentina',
          goalsHome: '',
          goalsGuest: '',
          date: '2022-12-09T20:00:00'
        })
        roundMatches.push({
          codeHome: '',
          codeGuest: '',
          colorHome: '#ACACAC',
          colorGuest: '#ACACAC',
          teamHome: 'MAR/ESP',
          teamGuest: 'POR/SWI',
          goalsHome: '',
          goalsGuest: '',
          date: '2022-12-10T16:00:00'
        })
        roundMatches.push({
          codeHome: 'gb-eng',
          codeGuest: 'fr',
          colorHome: '',
          colorGuest: '',
          teamHome: this.$store.state.language === 'de' ? 'England' : 'England',
          teamGuest:this.$store.state.language === 'de' ? 'Frankreich' : 'France',
          goalsHome: '',
          goalsGuest: '',
          date: '2022-12-10T20:00:00'
        })
      } else if(round === 27) {
        roundMatches.push({
          codeHome: 'ar',
          codeGuest: 'hr',
          colorHome: '',
          colorGuest: '',
          teamHome: this.$store.state.language === 'de' ? 'Argentinien' : 'Argentina',
          teamGuest: this.$store.state.language === 'de' ? 'Kroatien' : 'Croatia',
          goalsHome: '',
          goalsGuest: '',
          date: '2022-12-13T20:00:00'
        })
        roundMatches.push({
          codeHome: 'fr',
          codeGuest: 'ma',
          colorHome: '',
          colorGuest: '',
          teamHome:this.$store.state.language === 'de' ? 'Frankreich' : 'France',
          teamGuest: this.$store.state.language === 'de' ? 'Marokko' : 'Morocco',
          goalsHome: '',
          goalsGuest: '',
          date: '2022-12-14T20:00:00'
        })
      } else if(round === 28) {
        roundMatches.push({
          codeHome: '',
          codeGuest: '',
          colorHome: '#ACACAC',
          colorGuest: '#ACACAC',
          teamHome: this.$store.state.language === 'de' ? 'Verlierer HF1' : 'Loser SF1',
          teamGuest: this.$store.state.language === 'de' ? 'Verlierer HF2' : 'Loser SF2',
          goalsHome: '',
          goalsGuest: '',
          date: '2022-12-17T16:00:00'
        })
      } else if(round === 29) {
        roundMatches.push({
          codeHome: '',
          codeGuest: '',
          colorHome: '#ACACAC',
          colorGuest: '#ACACAC',
          teamHome: this.$store.state.language === 'de' ? 'Sieger HF1' : 'Winner SF1',
          teamGuest: this.$store.state.language === 'de' ? 'Sieger HF2' : 'Winner SF2',
          goalsHome: '',
          goalsGuest: '',
          date: '2022-12-18T16:00:00'
        })
      }
      return roundMatches
    },
    getRoundMatchesStatic (tournament, round) {
      var roundMatches = []
      if(round === 9) {
        roundMatches.push({
          codeHome: 'gb-wls',
          codeGuest: '',
          colorHome: '#ACACAC',
          colorGuest: '#ACACAC',
          teamHome: 'Wales',
          teamGuest: 'Zweiter B',
          goalsHome: '',
          goalsGuest: '',
          date: '2021-06-26T18:00:00'
        })
        roundMatches.push({
          codeHome: 'it',
          codeGuest: 'at',
          colorHome: '#ACACAC',
          colorGuest: '#ACACAC',
          teamHome: 'Italien',
          teamGuest: 'Österreich',
          goalsHome: '',
          goalsGuest: '',
          date: '2021-06-26T21:00:00'
        })
        roundMatches.push({
          codeHome: 'nl',
          codeGuest: '',
          colorHome: '#ACACAC',
          colorGuest: '#ACACAC',
          teamHome: 'Niederlande',
          teamGuest: 'Dritter D/E/F',
          goalsHome: '',
          goalsGuest: '',
          date: '2021-06-27T18:00:00'
        })
        roundMatches.push({
          codeHome: '',
          codeGuest: '',
          colorHome: '#ACACAC',
          colorGuest: '#ACACAC',
          teamHome: 'Sieger B',
          teamGuest: 'Dritter A/D/E/F',
          goalsHome: '',
          goalsGuest: '',
          date: '2021-06-27T21:00:00'
        })
        roundMatches.push({
          codeHome: '',
          codeGuest: '',
          colorHome: '#ACACAC',
          colorGuest: '#ACACAC',
          teamHome: 'Zweiter D',
          teamGuest: 'Zweiter E',
          goalsHome: '',
          goalsGuest: '',
          date: '2021-06-28T18:00:00'
        })
        roundMatches.push({
          codeHome: '',
          codeGuest: '',
          colorHome: '#ACACAC',
          colorGuest: '#ACACAC',
          teamHome: 'Sieger F',
          teamGuest: 'Dritter A/B/C',
          goalsHome: '',
          goalsGuest: '',
          date: '2021-06-28T21:00:00'
        })
        roundMatches.push({
          codeHome: '',
          codeGuest: '',
          colorHome: '#ACACAC',
          colorGuest: '#ACACAC',
          teamHome: 'Sieger D',
          teamGuest: 'Zweiter F',
          goalsHome: '',
          goalsGuest: '',
          date: '2021-06-29T18:00:00'
        })
        roundMatches.push({
          codeHome: '',
          codeGuest: '',
          colorHome: '#ACACAC',
          colorGuest: '#ACACAC',
          teamHome: 'Sieger E',
          teamGuest: 'Dritter A/B/C/D',
          goalsHome: '',
          goalsGuest: '',
          date: '2021-06-29T21:00:00'
        })
      } else if(round === 10) {
        roundMatches.push({
          codeHome: 'ch',
          codeGuest: 'es',
          colorHome: '#ACACAC',
          colorGuest: '#ACACAC',
          teamHome: 'Schweiz',
          teamGuest: 'Spanien',
          goalsHome: '',
          goalsGuest: '',
          date: '2021-07-02T18:00:00'
        })
        roundMatches.push({
          codeHome: 'be',
          codeGuest: 'it',
          colorHome: '#ACACAC',
          colorGuest: '#ACACAC',
          teamHome: 'Belgien',
          teamGuest: 'Italien',
          goalsHome: '',
          goalsGuest: '',
          date: '2021-07-02T21:00:00'
        })
        roundMatches.push({
          codeHome: 'cz',
          codeGuest: 'dk',
          colorHome: '#ACACAC',
          colorGuest: '#ACACAC',
          teamHome: 'Tschechien',
          teamGuest: 'Dänemark',
          goalsHome: '',
          goalsGuest: '',
          date: '2021-07-03T18:00:00'
        })
        roundMatches.push({
          codeHome: 'ua',
          codeGuest: 'gb-eng',
          colorHome: '#ACACAC',
          colorGuest: '#ACACAC',
          teamHome: 'Ukraine',
          teamGuest: 'England',
          goalsHome: '',
          goalsGuest: '',
          date: '2021-07-03T21:00:00'
        })
      } else if(round === 11) {
        roundMatches.push({
          codeHome: 'it',
          codeGuest: 'es',
          colorHome: '#ACACAC',
          colorGuest: '#ACACAC',
          teamHome: 'Italien',
          teamGuest: 'Spanien',
          goalsHome: '',
          goalsGuest: '',
          date: '2021-07-06T21:00:00'
        })
        roundMatches.push({
          codeHome: '',
          codeGuest: '',
          colorHome: '#ACACAC',
          colorGuest: '#ACACAC',
          teamHome: 'Ukraine/England',
          teamGuest: 'Tschechien/Dänemark',
          goalsHome: '',
          goalsGuest: '',
          date: '2021-07-07T21:00:00'
        })
      } else if(round === 12) {
        roundMatches.push({
          codeHome: 'it',
          codeGuest: 'gb-eng',
          colorHome: '#ACACAC',
          colorGuest: '#ACACAC',
          teamHome: 'Italien',
          teamGuest: 'England',
          goalsHome: '',
          goalsGuest: '',
          date: '2021-07-11T21:00:00'
        })
      } else if(round === 20) {
        roundMatches.push({
          codeHome: '',
          codeGuest: '',
          colorHome: '#ACACAC',
          colorGuest: '#ACACAC',
          teamHome: 'Sieger Gr. I',
          teamGuest: 'Zweiter Gr. II',
          goalsHome: '',
          goalsGuest: '',
          date: '2022-01-28T18:00:00'
        })
        roundMatches.push({
          codeHome: '',
          codeGuest: '',
          colorHome: '#ACACAC',
          colorGuest: '#ACACAC',
          teamHome: 'Sieger Gr. II',
          teamGuest: 'Zweiter Gr. I',
          goalsHome: '',
          goalsGuest: '',
          date: '2022-01-28T20:30:00'
        })
      } else if(round === 21) {
        roundMatches.push({
          codeHome: '',
          codeGuest: '',
          colorHome: '#ACACAC',
          colorGuest: '#ACACAC',
          teamHome: 'Dritter Gr. I',
          teamGuest: 'Dritter Gr. II',
          goalsHome: '',
          goalsGuest: '',
          date: '2022-01-28T15:30:00'
        })
      } else if(round === 22) {
        roundMatches.push({
          codeHome: '',
          codeGuest: '',
          colorHome: '#ACACAC',
          colorGuest: '#ACACAC',
          teamHome: 'Verlierer HF I',
          teamGuest: 'Verlierer HF II',
          goalsHome: '',
          goalsGuest: '',
          date: '2022-01-30T15:30:00'
        })
      } else if(round === 23) {
        roundMatches.push({
          codeHome: '',
          codeGuest: '',
          colorHome: '#ACACAC',
          colorGuest: '#ACACAC',
          teamHome: 'Sieger HF I',
          teamGuest: 'Sieger HF II',
          goalsHome: '',
          goalsGuest: '',
          date: '2022-01-30T18:00:00'
        })
      }
      return roundMatches
    },
    slideChangeTransitionEnd() {
      if(this.tabsnew) {
        const t = this.tabsnew[this.swiper.activeIndex]
        if(t) {
          this.activeTab = t.name
          const tabbar = document.getElementById('tournament_nav')
          const tab = document.getElementById(this.activeTab)
          tabbar.scrollLeft = tab.offsetLeft - (tabbar.offsetWidth / 4 + tab.offsetWidth / 2)
        }
      }
    },
    getGroupTable(positions) {
      var tablesPositions = []
      for(var j=0; j<positions.length; j++) {
        const position = positions[j]
        const team = this.$store.state.teams.filter(s => s.id === position.fk_team)[0]
        if(team === undefined) {
          continue
        }
        tablesPositions.push({
          position: position.platz,
          code: team.logo,
          color: team.color,
          name: this.$store.state.language === 'de' ? team.name : team.name_int,
          mathes: position.spiele,
          points: position.punkte,
          goals: position.torepositiv,
          goalsAgainst: position.torenegativ,
        })
      }
      tablesPositions.sort(function(a, b) {
        return a.position - b.position
      })
      return tablesPositions
    },
    getRoundMatches(matches) {
      var roundMatches = []
      for(var j=0; j<matches.length; j++) {
        const tournamentmatch = matches[j]
        const match = this.$store.state.matches.filter(s => s.id === tournamentmatch.fk_match)[0]
        if(match === undefined) {
          continue
        }
        const th = this.$store.state.teams.filter(s => s.id === match.fk_team_home)[0]
        const tg = this.$store.state.teams.filter(s => s.id === match.fk_team_guest)[0]
        if(th === undefined || tg === undefined) {
          continue
        }
        roundMatches.push({
          codeHome: th.logo,
          codeGuest: tg.logo,
          colorHome: th.color,
          colorGuest: tg.color,
          teamHome: this.$store.state.language === 'de' ? th.shortname : th.shortname_int,
          teamGuest: this.$store.state.language === 'de' ? tg.shortname : tg.shortname_int,
          goalsHome: this.isNumeric(match.goalshome) ? match.goalshome : (this.isNumeric(match.livegoalshome) ? match.livegoalshome : ''),
          goalsGuest: this.isNumeric(match.goalsguest) ? match.goalsguest : (this.isNumeric(match.livegoalsguest) ? match.livegoalsguest : ''),
          date: match.matchdate
        })
      }
      roundMatches.sort(function (a, b) {
        return Date.parse(a.date) - Date.parse(b.date)
      })
      return roundMatches
    },
    getTabIndex(tabname) {
      for(var i=0; i<this.tabsnew.length; i++) {
        if(this.tabsnew[i].name === tabname) {
          return i
        }
      }
      return 0
    },
    handleClick(tabname) {
      this.activeTab = tabname
      const tabbar = document.getElementById('tournament_nav')
      const tab = document.getElementById(tabname)
      tabbar.scrollLeft = tab.offsetLeft - (tabbar.offsetWidth/4 + tab.offsetWidth/2)
      this.swiper.slideTo(this.getTabIndex(tabname))
    }
  }
}
</script>

<style lang="scss">

  .slide-leave-active,
  .slide-enter-active {
    transition: 300ms;
  }
  .slide-enter {
    transform: translate(100%, 0);
  }
  .slide-leave-to {
    transform: translate(-100%, 0);
  }
  
  .tournament-slider {
    overflow: hidden;
    position: relative;
  }
  
  .tournament-slider component {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right:0;
  }

  .tournament {
    padding-left: 0;
    padding-right: 0;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  #tournament_nav::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE and Edge */
  #tournament_nav {
    display: flex;
    justify-content: flex-start;
    -ms-overflow-style: none;
    margin: 12px 0 24px;
    width: 100%;
    overflow-x: scroll;
    scroll-behavior: smooth; /* <--- */
    white-space: nowrap;
    box-shadow: inset 0 -2px 0 0 rgba($colorGreyDark, .08);

    @media screen and (min-width: 768px) {
      margin: 16px 0 48px;
      padding: 0 16px;
      box-sizing: border-box;
    }
  }
  
  .tournament_content {
    width: 100%;
  }

  .tournament-slider-item {

    @media screen and (min-width: 768px) {
      max-width: 576px;
      margin: 0px auto;
    }
  }
  
  .tournament_tab {
    font-size: 17px;
    color: $colorGreyMid;
    padding: 12px 0;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 16px;
    }

    &:first-child {
      margin-left: 16px;

      @media screen and (min-width: 768px) {
        margin-left: auto;
      }
    }

    &:last-child {
      margin-right: 16px;

      @media screen and (min-width: 768px) {
        margin-right: auto;
      }
    }
  }
  
  .tournament_tab_selected {
    color: var(--color-primary);
    font-weight: 500;
    box-shadow: inset 0 -2px 0 0 rgba(var(--color-primary-rgb), 1);
  }

  .tournament_tab_badge {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      right: -6px;
      top: 12px;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background: $colorNegative;
    }
  }

  /*.last_tab {
    padding-right: 100px;
  }*/
  
</style>
