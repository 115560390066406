<template>
    <table id="bg_competition_table" class="table">
        <thead>
            <th>#</th>
            <th class="col_team_name" style="text-align: left;">{{ $t('team') }}</th>
            <th class="mobile-hidden">{{ $t('matches') }}</th>
            <th class="mobile-hidden">{{ $t('matches_won_short') }}</th>
            <th class="mobile-hidden">{{ $t('matches_drawn_short') }}</th>
            <th class="mobile-hidden">{{ $t('matches_lost_short') }}</th>
            <th class="mobile-hidden">{{ $t('goals') }}</th>
            <th>+/-</th>
            <th>{{ $t('rankings_table_header_points') }}</th>
            </thead>
        <tbody></tbody>
        <tr
            v-for="(position, index) in positions"
            :key="position.fk_team"
            class=""
            v-bind:class="{ position_highlight: addSeparator(index + 1) }"
        >
            <td>{{ index + 1 }}.</td>
            <td class="col_team_name">
                <img v-if="teamLogo(position.fk_team)" :src="teamLogo(position.fk_team)" width="24">
                <svg v-else width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <path d="M16.6788228,14.4666528 C16.6784319,14.5535821 16.6782365,14.6380312 16.6782365,14.72 C16.6782365,17.92 17.0754204,17.92 17.0754204,22 L6.90751142,22 C6.90751142,18 7.30469536,18 7.30469536,14.72 C7.30469536,14.5820958 7.30416879,14.4377219 7.30311564,14.2868783 C7.16189664,12.9183228 6.95059177,11.7026967 6.66920105,10.64 L6.38887711,11.2610852 C6.32215943,11.4089048 6.15565267,11.4837271 6.0008221,11.4354635 L3.22551831,10.5703504 C3.06290094,10.5196596 2.96820511,10.350765 3.00978727,10.1855836 L4.33308578,4.92889834 C4.35386243,4.84636495 4.40656906,4.77546278 4.47961453,4.73178454 L6.72830973,3.38715539 C6.78448244,3.35361757 6.84801543,3.33879392 6.91053769,3.34222149 C6.90953086,3.3215047 6.9085221,3.3007642 6.90751142,3.28 L9.40273013,2 C10.2740705,2.5 11.1398271,2.75 12,2.75 C12.8601729,2.75 13.7202401,2.5 14.5802017,2 L16.8912572,3.18552777 C16.96049,3.22094519 17.0129393,3.27936302 17.0415261,3.3484881 C17.11879,3.33240258 17.2010587,3.34498492 17.2716903,3.38715539 L19.5203855,4.73178454 C19.5934309,4.77546278 19.6461376,4.84636495 19.6669142,4.92889834 L20.9902127,10.1855836 C21.0317949,10.350765 20.9370991,10.5196596 20.7744817,10.5703504 L17.9991779,11.4354635 C17.8443473,11.4837271 17.6778406,11.4089048 17.6111229,11.2610852 L17.330799,10.64 C17.0371467,11.7490035 16.8198213,13.0245545 16.6788228,14.4666528 Z" id="basic-jersey" :style="{'fill': getTeamColor(position.fk_team )}"></path>
                </svg>{{ getTeamFullName(position.fk_team, position.team) }}</td>
            <td class="col_value mobile-hidden">{{ position.matches_total }}</td>
            <td class="col_value mobile-hidden">{{ position.matches_won }}</td>
            <td class="col_value mobile-hidden">{{ position.matches_draw }}</td>
            <td class="col_value mobile-hidden">{{ position.matches_lost }}</td>
            <td v-if="position.goals_scored && position.goals_against" class="col_value mobile-hidden">{{ position.goals_scored }}:{{ position.goals_against }}</td>
            <td v-else class="col_value mobile-hidden">0:0</td>
            <td v-if="position.goals_diff === 0 || position.goals_diff === null || position.goals_diff === undefined" class="col_value">0</td>
            <td v-else-if="position.goals_diff > 0" class="col_value">+{{ position.goals_diff }}</td>
            <td v-else class="col_value">{{ position.goals_diff }}</td>
            <td class="col_value col_points">{{ position.points }}</td>
        </tr>
        <tfoot></tfoot>
    </table>
</template>

<script>
  export default {
    name: 'LeagueTable',
    data() {
      return {
        separators: new Map(
            [
                ["1", [5,6,7,15,16]], 
              ["2", [2,3,15,16]],
              ["3", [2,3,16]],
              ["13", [6]],
              ["13m", [2,3,4,5]],
              ["13q", [2,5]],
              ["14", [1,2,10]],
              ["19", [2,3]],
              ["71", [2,3,4,5,17,18]],
              ["76", [1,2,4,10]],
              ["91", [4,5,17]],
              ["97", [4,5,6,17]],
              ["107", [1,13,15,16]],
              ["111", [4,5,6,17]],
              ["115", [6]],
              ["115m", [2,4]],
              ["115q", [4,5]],
              ["116", [2,4,16]],
              ["564", [1,15,16]],
              ["2255", [1,14,17]],
              ["2256", [1,13,15]],
              ["2257", [1,14,16]],  
            ]
        )
      }
    },
    props: {
      groupAt: {
        type: String,
        default: () => {
          return ''
        }
      },
      positions: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    methods: {
      addSeparator(index) {
        if (this.$store.state.leagueId) {
          const s = this.separators.get('' + this.$store.state.leagueId + this.groupAt)
          if(s != null) {
            return s.includes(index)
          }
        }
        return false
      },
      teamLogo(id) {
        const l = this.getTeamLogo(id)
        if (l) {
          return this.getBGValue('spmau') + '/images/jerseys/24/' + l
        }
        return null
      }
    },
    computed: {
    }
  }
</script>

<style lang="scss" scoped>

    .table {

        tr {
            &.position_highlight {
                td {
                    border-bottom: 2px solid rgba($colorGreyDark, 0.16)
                }
            }
        }

        td {

            &.col_team_name {
                padding: 10px 0 9px;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                white-space: normal;
                width: 100% !important;

                img, svg {
                    margin-right: 8px;
                }
            }

            &:first-child {
                width: 28px;
                max-width: 28px;
                padding-left: 8px;
                text-align: center;
            }
        }

        @media screen and (max-width: 767px) {
            .mobile-hidden {
                display: none;
            }
        }

        @media screen and (min-width:768px) {
            table-layout: fixed;

            td, th {
                text-align: center;

                &:first-child {
                    text-align: left;
                    padding-left: 16px;
                    width: 24px;
                }
                &.col_team_name {
                    text-align: left;
                    width: 55%;
                }
                &:last-child {
                    text-align: right;
                }
            }
        }
    }

</style>
